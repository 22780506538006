import { Button } from 'react-bootstrap';
import React from 'react';

const ImageButton = (props) => {

    return (
      <Button className="btn_base" variant={props.selected ? "secondary": "link"} onClick={props.onPress}>
        {props.children}
      </Button>
    );

}

export default ImageButton