import React, { useEffect } from 'react';
import { getObdobi, jidelnicekSelectObbodi } from '../../redux/actions/jidelnicek';
import { useDispatch, useSelector } from 'react-redux';
import { userInfo } from '../../redux/actions/stravnik';
import { ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap';

const ObdobiSelect = () => {

    const dispatch = useDispatch()
    let {obdobi, obdobiLoaded, selObd} = useSelector(mapStateToProps)

    let obdobiMapped = []
    let obdobiValues = {}

    const handleJidelnicekObdChange = (data) => {
        dispatch(jidelnicekSelectObbodi(obdobiValues[data]))
    }

    if(obdobi && obdobi.length > 0) {
        obdobiMapped = [[...obdobi].map((obd) => {
            let showVal = obd.m + "/" + (parseInt(obd.r)+2000)
            obdobiValues[showVal] = {...obd}
            return showVal
        })]
    }

    useEffect(() => {
        if(!obdobiLoaded) {
            dispatch(userInfo())
            dispatch(getObdobi()).catch(() => {})
        }
      }, [obdobiLoaded, dispatch]
    );

    let selObdText = selObd && (selObd.m + "/" + (parseInt(selObd.r)+2000))

    return (
        <>
        {obdobiMapped && obdobiMapped.length > 0 && 
        <DropdownButton as={ButtonGroup} id="dropdown-basic-button" title={selObdText}>
        {obdobiMapped[0].map((obd) => 
            <Dropdown.Item key={obd} onClick={() => handleJidelnicekObdChange(obd)}>{obd}</Dropdown.Item>
            )}
    </DropdownButton>}</>)
}

const mapStateToProps = (state) => {
    return {
        obdobi: state.jidelnicek.obdobi,
        selObd: state.jidelnicek.selectedObdobi,
        obdobiLoaded: state.jidelnicek.obdobiLoaded
    }
}

export default ObdobiSelect