import React from "react";
import { Modal } from "react-bootstrap";

const ModalView = (props) => {

  return (
    <Modal show={props.visible} onHide={props.handleHide}>
      <Modal.Header closeButton>
        <Modal.Title>{props.heading}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {props.children}
      </Modal.Body>
    </Modal>
  );
};

export default ModalView;