import moment from "moment";
import { JIDELNICEK_LIST, JIDELNICEK_LIST_OK, JIDELNICEK_OBDOBI, JIDELNICEK_OBDOBI_OK, JIDELNICEK_RESET_OBDOBI, JIDELNICEK_SELECT_JIDLO, JIDELNICEK_SELECT_OBDOBI, JIDELNICEK_SET_RATING, JIDELNICEK_STATIC_LIST, JIDELNICEK_STATIC_LIST_OK, JIDELNICEK_TOGGLE_ALLERGENS } from "../actions/jidelnicek";
import { STRAVNIK_SET_ACTIVE_ACCOUNT } from "../actions/stravnik";

const iniState = {
    status: null,
    statusMessage: "",
    list: [],
    listHash: null,
    listExpiration: null,
    staticList: [],
    obdobi: [],
    obdobiLoaded: false,
    selectedObdobi: {},
    alergenModalShown: false,
    rating: null
}
export default (state = iniState, action) => {
    switch (action.type) {
        case JIDELNICEK_OBDOBI:
            if (action.status === JIDELNICEK_OBDOBI_OK) {
                let selectedObd = {}
                if(action.data.obdobi.length > 0) {
                    let actObd = action.data.obdobi.filter((obd) => obd.act)
                    if(actObd && actObd.length > 0) {
                        selectedObd = actObd[0]
                    } else {
                        selectedObd = action.data.obdobi[action.data.obdobi.length-1]
                    }
                }
                return {
                    ...state,
                    status: action.status,
                    statusMessage: "",
                    obdobi: action.data.obdobi,
                    obdobiLoaded: true,
                    selectedObdobi: selectedObd
                }
            } else {
                return {
                    ...state,
                    status: action.status,
                    obdobiLoaded: false,
                    statusMessage: action.statusMessage
                }
            }
        case JIDELNICEK_LIST:
            if (action.status === JIDELNICEK_LIST_OK) {
                let exp = moment()
                exp.add(20, 'minutes')
                return {
                    ...state,
                    status: action.status,
                    statusMessage: "",
                    list: action.data.jidla.map((day) => {
                        if(day.obedy && day.obedy.data) {
                            day.obedy.data = day.obedy.data.map((obed) => {
                                return {...obed, selected: obed.objednano === true}
                            })
                        }
                        if(day.snidane && day.snidane.data) {
                            day.snidane.data = day.snidane.data.map((snidane) => {
                                return {...snidane, selected: snidane.objednano === true}
                            })
                        }
                        if(day.vecere && day.vecere.data) {
                            day.vecere.data = day.vecere.data.map((vecere) => {
                                return {...vecere, selected: vecere.objednano === true}
                            })
                        }
                        return day
                    }),
                    listHash: action.data.hashcheck,
                    listExpiration: exp,
                    rating: null
                }
            } else {
                return {
                    ...state,
                    status: action.status,
                    statusMessage: action.statusMessage
                }
            }
            case JIDELNICEK_STATIC_LIST:
                if (action.status === JIDELNICEK_STATIC_LIST_OK) {
                    return {
                        ...state,
                        status: action.status,
                        statusMessage: "",
                        staticList: action.data.weeks
                    }
                } else {
                    return {
                        ...state,
                        status: action.status,
                        statusMessage: action.statusMessage
                    }
                }            
        case JIDELNICEK_SELECT_JIDLO:
            let jidelnicek = [...state.list]
            let actDenIdx = -1
            jidelnicek.map((dny, idx) => {
                if(dny.datum === action.data.datum) {
                    actDenIdx = idx
                }
            })
            
            jidelnicek[actDenIdx][action.data.typ].data = jidelnicek[actDenIdx][action.data.typ].data.map((jidl) => {
                return {
                    ...jidl,
                    selected: jidl.hodnota === action.data.hodnota
                }
            })
            
            return {
                ...state,
                list: jidelnicek
            }
        case JIDELNICEK_SELECT_OBDOBI:
            return {
                ...state,
                selectedObdobi: action.data
            }
        case JIDELNICEK_SET_RATING:
            return {
                ...state,
                rating: action.data
            }            
        case JIDELNICEK_TOGGLE_ALLERGENS:
            return {
                ...state,
                alergenModalShown: !state.alergenModalShown
            }
        case STRAVNIK_SET_ACTIVE_ACCOUNT:
        case JIDELNICEK_RESET_OBDOBI:
            return {
                ...state,
                obdobiLoaded: false,
                list: []
            }  
        default:
            if (action.statusMessage && action.statusMessage.generalState && action.statusMessage.generalState === "notAuthorized") {
                return iniState;
            } else {
                return state;
            }
    }
}