export default {
    welcome: {
        header: "Welcome to eStrava",
        text1: "Choose your language",
        text2: "Proceed by selecting canteen you wish to login to",
        text3: "In next step, you will be asked to login to selected canteen.",
        choose: "Choose canteen"
    },    
    login: {
        selected: "Chosen canteen",
        form: {
            username: "User code",
            pass: "Password",
            send: "Login"
        },
        card: {
            heading: "Place chip to reader"            
        },
        system: {
            original: "Original eStrava"
        }
    },
    recovery: {
        email: "Actual email address",
        button: "Send forgotten password",
        confirm: "New password has been sent to entered email address"
    },
    gdpr: {
        header: "Consent to the processing of personal data",
        text: "I hereby give my consent to Ulrich Software s.r.o. for processing my personal data (name, school number, email) in order to provide E-strava services. The data will be not shared with third parties. The granting of the consent is voluntary and the consent can be withdrawn at any time by writing an email to: info@ulrichsw.cz. Withdrawal of the consent will not affect the legality of the previous data processing. According to Art. 8 (1) GDPR only persons over the age of 16 are able to give a lawful consent. For children under the age of 16 the consent shall be given by their parents (or other legal guardian).",
        confirm: "I conset"
    },
    account: {
        action: {
            logout: "Logout",
            changePwd: "Change password",
            changeEml: "Change email",
            changeGdpr: "Revoke",
            profiles: "Manage profiles"
        },
        profile: {
            actual: "Actual profile",
            heading: "Manage profiles",
            add: "Add another",
            info: "Touch to select profile. You can delete profiles or add new. Maximum allowed number of profiles is 10.",
            deleteConfirm: "Do you really want to delete this profile?",
            choose: "Choose"
        },        
        email: {
            label: "Email address",
            heading: "Change email",
            original: "Original address",
            new: "New address",
            confirm: "Confirm change",
            missing: "Email address is not included in current user data. It must be filled in to proceed with using application."
        },
        pwd: {
            heading: "Change password",
            original: "Current password",
            pass1: "New password",
            pass2: "New password (again)",
            confirm: "Confirm change"
        },
        gdpr: {
            heading: "Revoke GDPR consent",
            message: "By revoking consent you will no longer be able to use web portal and/or mobile application. If you really want to revoke your consent, use button below. We will send you revocation confirmation into your mailbox.",
            confirm: "Revoke"
        },
        jidelna: "Canteen",
        user: "Boarder",
        gdprDate: "GDPR consent",
        changeConfirmation: "Change was processed successfully"
    },
    zarizeni: {
        search: {
            placeholder: "Search",
            cancel: "Cancel"
        },
        notAll: "List of canteens is currently restricted to chosen ones"
    },
    info: {
        header: "Canteen information ",
        buttons: {
            staticVersion: "Menu with ratings",
            textVersion: "Detail menu",
            deadlines: "Ordering deadlines"
        },
        jidelnicek: {
            heading: "Menu with ratings",
            week: "Week"
        },
        jidelnicekText: {
            heading: "Detail menu"
        },
        deadlines: {
            heading: "Ordering deadlines",
            order: "Ordering is possible till ",
            sameDay: " hours at the same day.",
            workingDay1: " hours 1 working day before.",
            workingDayMore: " hours [X] working days before.",
            mondays: " for monday"
        }
    },
    finance: {
        header: "Financial information",
        send: {
            button: "Send report to email",            
            success: {
                header: "Send report",
                text: "Report was successfully sent to email [EMAIL]"
            }
        },
        original: {
            zaloha: "Prepaid",
            odhlasky1: "Deannounce",
            odhlasky2: "Deannounce supplementary"
        }
    },
    obdobi: {
        heading: "Change of month",
        original: "Current month",
        new: "New month",
        notavailable: "Another month is not available"
    },      
    jidelnicek: {
        header: "Menu",
        unorder: "DE-ORDER",
        soup: "Soup",
        lunch: "Lunch",
        breakfast: "Breakfast",
        pickup: "Pickup",
        dinner: "Dinner",
        save: "Send",
        saved: "Order was accepted and confirmation has been sent to email [EMAIL]",
        savedrating: "Order was accepted and confirmation has been sent to email [EMAIL]\n\nMeal rating was saved.",
        savedratingonly: "Meal rating was saved.",
        collision: "Order was not saved! There were changes made from canteen side. You need to check current order and correct it if needed.",
        nodata: "Currently you are not subscribed, please make an order",
        reasonN: "Not paid",
        reason9: "Lately de-ordered",
        allergens: {
            button: "Allergens",
            heading:  'Allergens',
            alergen1: 'Cereals containing gluten, namely: wheat (such as spelt and khorasan wheat), rye, barley, oats or their hybridised strains, and products thereof',
            alergen2: 'Crustaceans and products thereof',
            alergen3: 'Eggs and products thereof',
            alergen4: 'Fish and products thereof',
            alergen5: 'Peanuts and products thereof',
            alergen6: 'Soybeans and products thereof',
            alergen7: 'Milk and products thereof (including lactose)',
            alergen8: 'Nuts, namely: almonds (Amygdalus communis L.), hazelnuts (Corylus avellana), walnuts (Juglans regia), cashews (Anacardium occidentale), pecan nuts (Carya illinoinensis (Wangenh.) K. Koch), Brazil nuts (Bertholletia excelsa), pistachio nuts (Pistacia vera), macadamia or Queensland nuts (Macadamia ternifolia), and products thereof',
            alergen9: 'Celery and products thereof',
            alergen10: 'Mustard and products thereof',
            alergen11: 'Sesame seeds and products thereof',
            alergen12: 'Sulphur dioxide and sulphites at concentrations of more than 10 mg/kg or 10 mg/litre in terms of the total SO2 which are to be calculated for products as proposed ready for consumption or as reconstituted according to the instructions of the manufacturers',
            alergen13: 'Lupin and products thereof',
            alergen14: 'Molluscs and products thereof',
            alergen0: 'Not available',
            close: "Close"
        },
        needSave: "Changes you made must be sent!",
        inactive: "Canteen is not working now, orders are not available.",
        rating: {
            button: "Rate your meal",
            heading: "Meal rating",
            rating1: "Yummy",
            rating2: "Tasty",
            rating3: "OK",
            rating4: "Poor",
            rating5: "Never again",
            save: "Rate"
        },
        move: {
            up: "Up",
            down: "Down"
        }
    },
    settings: {
        language: {
            header: "Application language"
        },
        settings: {
            header: "Visibility settings",
            onlynew: "Show only days in future",
            eaten: "Show if food was really eaten"
        }
    },
    navigator: {
        tabs: {
            menu: "Menu ",
            info: "Information ",
            finance: "Financial ",
            account: "Account ",
            settings: "Settings ",
            about: "About "
        },
        headers: {
            welcome: "Introduction",
            login: "Login",
            passwordRecovery: "Forgotten password",
            selection: "Canteen selection"
        }
    },
    error: {
        types: {
            entity_error: "Data error",
            authentication_error: "Authentication error",
            application_error: "Application error"
        },
        messages: {
            wrong_token: "Wrong token",
            general_application_error: "General application error",
            missing_parameters: "Mandatory fields are missing",
            wrong_credentials: "Wrong authentication credentials",
            data_processing_error: "Data processing error",
            missing_email: "Your account does not have an email address. Password can not be sent. Contact your canteen chief",
            wrong_email: "Entered email address differ from your account. Password can not be sent. Contact your canteen chief",
            wrong_original_password: "Current password is not correct",
            account_already_exists: "You are already logged under this account, switch profile tu use it."
        }
    },
    validation: {
        required: "Field is required",
        email: "Email is required in correct format",
        passwordMatch: "Passwords must match"
    },
    confirmation: {
        yes: "Yes",
        no: "No"
    }
}