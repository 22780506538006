import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import transCS from './locales/cs'
import transSK from './locales/sk'
import transEN from './locales/en'

export default function configureI18n(lngDetector, callback) {
i18n
  .use(lngDetector)
// pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    detection: {
      order: ['langauth'],
      caches: ['langauth'],
      excludeCacheFor: ['cimode']
    },
    fallbackLng: 'cs',
    debug: true,
    resources: {
        cs: {translation: transCS},
        sk: {translation: transSK},
        en: {translation: transEN}
    },
    keySeparator: ".",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  }, callback)
  return i18n
}