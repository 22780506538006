import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { VisibilityOutlined, VisibilityOffOutlined } from '@material-ui/icons';

const Input = (props) => {
  
  let [shown, setShown] = useState(false)

  let { name, register, errors, label, rules, invalidMessage, placeholder, onChange, autoFocus, overrideType } = props;

  const formFieldRef = useRef(null);

  const formField = register(name, rules || {}) 

  const onChangeHandle = (e) =>
  {
    formField.onChange(e);
    if(onChange) {
      onChange(e)
    }
  }

  const refHandle = (e) =>
  {
      formField.ref(e)
      formFieldRef.current = e // you can still assign to ref
  }

  useEffect(() => {
    if(autoFocus > 0) {
      formFieldRef.current.focus();
    }
  }, [autoFocus])

  return (
    <Form.Group controlId={name}>
      {label && <Form.Label>{label}</Form.Label>}
      {props.secureTextEntry ? 
        <InputGroup>    
          <Form.Control placeholder={placeholder} type={!shown ? "password":"text"} {...formField} isInvalid={errors[name]} onChange={onChangeHandle} ref={refHandle}/>
          <InputGroup.Append>
            <Button variant="outline-secondary" onClick={() => setShown(!shown)}>
              {!shown ? <VisibilityOutlined fontSize="small"/> : <VisibilityOffOutlined fontSize="small"/>}
            </Button>
          </InputGroup.Append>
          {errors[name] && <Form.Control.Feedback type="invalid">{invalidMessage}</Form.Control.Feedback>}
        </InputGroup>
      :
      <>      
      <Form.Control placeholder={placeholder} type={overrideType ? overrideType : "text"} {...formField} isInvalid={errors[name]}  onChange={onChangeHandle} ref={refHandle}/>
      {errors[name] && <Form.Control.Feedback type="invalid">{invalidMessage}</Form.Control.Feedback>}
      </>
      }
    </Form.Group>
  );
}

export default Input