import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';

import MainView from '../components/views/MainView';
import { jidelnicekStaticList } from '../redux/actions/jidelnicek';
import JidelnicekStaticItem from '../components/items/JidelnicekStaticItem';
  
const JidelnicekStaticScreen = () => {

    const dispatch = useDispatch()
    let {jidelnicekStatic, activeAccount} = useSelector(mapStateToProps)    
    const { t } = useTranslation()

    useEffect(() => {
      dispatch(jidelnicekStaticList()).catch(() => {})
    }, []);

    let isActive = (activeAccount && activeAccount.zarizeni && activeAccount.zarizeni.viditelnost === 'A')

    return (
      <MainView loggedOnly={true}>

        {jidelnicekStatic && jidelnicekStatic.length > 0 && jidelnicekStatic.map((section) =>
        <div key={section.week_no}>
          <div className="lightBackground">
            <span className="heading">{t('info.jidelnicek.week')} {section.week_no}</span>
          </div>
          {section.data && section.data.map((item) =>
          <div key={item.datum.toString()}>
              <JidelnicekStaticItem data={item} />
          </div>
          )}
        </div>
        )}

        {!isActive && 
          <div className="centeredView basePaddingView">
            <span className="wizard_text danger">{t('jidelnicek.inactive')}</span>
          </div>}
      
      </MainView>
    )
  
}

const mapStateToProps = (state) => {
  return {
      jidelnicekStatic: state.jidelnicek.staticList,
      activeAccount: state.auth.actualAccount > -1 ? state.auth.accounts[state.auth.actualAccount] : null
  }
}

export default JidelnicekStaticScreen