import React from 'react';

import { useTranslation } from 'react-i18next';
import AppButton from '../buttons/AppButton';

const RemoveGdpr = (props) => {
    const { t } = useTranslation();

    const onSubmit = (data) => {
        props.handleSubmit && props.handleSubmit(data)
    }

    return (
        <>
            <p className="wizard_text">{t('account.gdpr.message')}</p>
            <AppButton style={{marginTop: 30}} onPress={onSubmit} label={t('account.gdpr.confirm')} />
        </>
    )
}

export default RemoveGdpr