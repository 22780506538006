import React from 'react';

const AllergenItem = (props) => {

  return (
    <p>
      <span className="boldText" style={{paddingRight: 12}}>{props.num}</span>
      <span>{props.t('jidelnicek.allergens.alergen'+props.num)}</span>
    </p>
  );

}

export default AllergenItem