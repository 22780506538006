import React from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { jidelnicekSelectJidlo } from '../../redux/actions/jidelnicek';

const JidelnicekRadioButton = (props) => {

    let {data, obj, i, typ} = props
    const dispatch = useDispatch()

    return (
      <>
      {obj.typ === "before" ? 
      <div className="custom-control jidelnicek_soup">
        <span>{obj.label}</span>
      </div>
      :
      <Form.Check 
        custom
        checked={obj.selected === true}
        type="radio"
        label={obj.value + ". " + (obj.label || "")}
        value={obj.value}
        style={{fontWeight: obj.selected ? "bold" : "normal"}}
        disabled={obj.typ === "before" || obj.zakazany === true}
        name={`volba-${typ}-${data.datum}`}
        id={`custom-${typ}-${data.datum}-${i}`}
        onChange={(value) => {dispatch(jidelnicekSelectJidlo(data.datum, typ, obj.hodnota))}}
      /> }    
      </>
    )
}

export default JidelnicekRadioButton