import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import AppButton from '../buttons/AppButton';
import Input from './Input';

const ChangePwd = (props) => {
    const { t } = useTranslation();
    const { register, formState: { isDirty, isValid, errors }, watch, handleSubmit } = useForm();

    const onSubmit = (data) => {
        props.handleSubmit && props.handleSubmit(data)
    }

    let passToCheck = watch("pass")

    return (
        <>
                <Input register={register} name="original" label={t('account.pwd.original')} 
                    rules={{required: true}} errors={errors} 
                    invalidMessage={t('validation.required')} 
                    secureTextEntry={true}
                />                    
                <Input register={register} name="pass" label={t('account.pwd.pass1')} 
                    rules={{required: true}} errors={errors} 
                    invalidMessage={t('validation.required')} 
                    secureTextEntry={true}
                />
                <Input register={register} name="passcheck" label={t('account.pwd.pass2')} 
                    rules={{
                        required: true,
                        validate: value => value === passToCheck
                    }} 
                    invalidMessage={t('validation.passwordMatch')} 
                    secureTextEntry={true}
                    errors={errors} 
                />
                <AppButton style={{marginTop: 30}} onPress={handleSubmit((data) => onSubmit(data))} label={t('account.pwd.confirm')} />
        </>
    )
}

export default ChangePwd