import React from 'react';

const JidelnaItem = (props) => {

  return (
    <li className="list-group-item">
        <button className="btn btn-link" onClick={props.onPress}>
        {props.data.nazev}&nbsp;<span className="badge badge-secondary">{props.data.zarizeni}</span>
        </button>  
    </li>
  );

}

export default JidelnaItem