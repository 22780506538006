import React, { useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AppButton from '../components/buttons/AppButton';
import ChangeEmail from '../components/forms/ChangeEmail';
import ChangePwd from '../components/forms/ChangePwd';
import RemoveGdpr from '../components/forms/RemoveGdpr';
import BoxItem from '../components/items/BoxItem';
import JidelnaBoxItem from '../components/items/JidelnaBoxItem';
import MainView from '../components/views/MainView';
import ModalView from '../components/views/ModalView';
import { changeEmail, changePwd, gdprRemove, logout, stravnikSetAccount } from '../redux/actions/stravnik';
import { formattedDate } from '../utils/conversions';
import { showAlert } from '../utils/errorMessages';
import { AccountCircle } from '@material-ui/icons';

const AccountScreen = ({ navigation }) => {

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  let {activeAccount, accounts, actualAccountIdx} = useSelector(mapStateToProps)

  const [pwdModalShown, togglePwdModal] = useState(false);
  const [emlModalShown, toggleEmlModal] = useState(false);
  const [gdprModalShown, toggleGdprModal] = useState(false);

  return (
    <MainView loggedOnly={true}>
      <div className="baseMarginView">
      <h2 className="wizard_header">{t('navigator.tabs.account')} </h2>

      {/* 
      <AppButton onPress={() => history.push('/profily')} label={t('account.action.profiles')} style_label={{flex: 1}}>
        <AccountCircle fontSize="small" style={{marginRight: 4}}/>
      </AppButton>
      */}

      <div>
      <span className="input_label">{t('account.profile.actual')}</span>

      <Row>
      {accounts && accounts.length > 0 && accounts.map((item, index) =>
        <Col sm={6} md={3} lg={2}>
            <Card>
            <Card.Body>
              <Card.Subtitle className="mb-2 text-muted">{t('account.user')}</Card.Subtitle>
              <Card.Title>{item.user.jmeno}{item.user.prijmeni}</Card.Title>
              <Card.Text>{item.user.username}</Card.Text>
              {actualAccountIdx !== index && 
              <Button variant="primary">{t("account.profile.choose")}</Button>}
            </Card.Body>
          </Card>
          </Col>
      )}
      </Row>
      </div>

      <span className="input_label">{t('account.jidelna')}</span>
      <Row>
          <Col md={8} lg={6}>
          {activeAccount && activeAccount.zarizeni && <JidelnaBoxItem data={activeAccount.zarizeni} />}
        </Col>
      </Row>

      <span className="input_label">{t('account.gdprDate')}</span>
      {activeAccount && activeAccount.user && 
        <Row>
          <Col sm={6}>
          <BoxItem style={{flex:1, marginRight: 10}} value={formattedDate(activeAccount.user.gdprsouhlas, 'DD.MM.YYYY HH:mm:ss')} />
          </Col>
          <Col sm={6}>
          <AppButton sm onPress={() => toggleGdprModal(true)} label={t('account.action.changeGdpr')} />
          </Col>
          </Row>
        }

      <span className="input_label">{t('account.email.label')}</span>
      <Row>
        <Col sm={6}>
          {activeAccount && activeAccount.user && <BoxItem value={activeAccount.user.email} />}
        </Col>
        <Col sm={6}>
          <AppButton sm onPress={() => toggleEmlModal(true)} label={t('account.action.changeEml')} />
          <AppButton sm onPress={() => togglePwdModal(true)} label={t('account.action.changePwd')} style={{marginLeft: 15}} />
        </Col>
      </Row>

    

        <ModalView visible={pwdModalShown} handleHide={() => togglePwdModal(false)} heading={t('account.pwd.heading')}>
            <ChangePwd 
              handleSubmit={(data) => {
                dispatch(changePwd(data)).then(() => {
                  togglePwdModal(false)
                  showAlert({title: 'account.action.changePwd', message: 'account.changeConfirmation'}, t)
                }).catch((err) => {
                  showAlert(err, t)
                })
              }}
            />
        </ModalView>

        <ModalView visible={emlModalShown} handleHide={() => toggleEmlModal(false)} heading={t('account.email.heading')}>
            <ChangeEmail 
              email={activeAccount && activeAccount.user && activeAccount.user.email} 
              handleSubmit={(data) => {
                dispatch(changeEmail(data)).then(() => {
                  toggleEmlModal(false)
                  showAlert({title: 'account.action.changeEml', message: 'account.changeConfirmation'}, t)
                }).catch((err) => {
                  showAlert(err, t)
                })
              }}
            />
        </ModalView>

        <ModalView visible={gdprModalShown} handleHide={() => toggleGdprModal(false)} heading={t('account.gdpr.heading')}>
            <RemoveGdpr 
              handleSubmit={() => {
                dispatch(gdprRemove()).then(() => {
                  toggleGdprModal(false)
                  showAlert({title: 'account.gdpr.heading', message: 'account.changeConfirmation'}, t)
                  dispatch(logout().catch(() => {}))
                }).catch((err) => {
                  toggleGdprModal(false)
                  showAlert(err, t)
                })
              }}
            />
        </ModalView>  

        </div>      
    </MainView>
  )
  
}

const mapStateToProps = (state) => {
  return {
    activeAccount: state.auth.actualAccount > -1 ? state.auth.accounts[state.auth.actualAccount] : null,
    accounts: state.auth.accounts,
    actualAccountIdx: state.auth.actualAccount
  }
}

export default AccountScreen