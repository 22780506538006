export default {
    welcome: {
        header: "Vítejte v aplikaci eStrava",
        text1: "Zvolte si jazyk aplikace",
        text2: "Pokračujte výběrem zařízení/jídelny ke které se chcete přihlásit",
        text3: "V dalším kroku budete vyzváni k přihlášení k vybranému zařízení.",
        choose: "Vybrat jídelnu"
    },    
    login: {
        selected: "Vybraná jídelna",
        form: {
            username: "Kód strávníka",
            pass: "Heslo",
            send: "Přihlásit"
        },
        card: {
            heading: "Přiložte čip"            
        },
        system: {
            original: "Původní eStrava"
        }
    },
    recovery: {
        email: "Aktuální emailová adresa",
        button: "Zaslat zapomenuté heslo",
        confirm: "Na uvedenou emailovou adresu bylo zasláno nové přístupové heslo"
    },
    gdpr: {
        header: "Souhlas se zpracováním osobních údajů",
        text: "Tímto uděluji firmě Ulrich Software s.r.o. souhlas se zpracováním mých osobních údajů (iniciály jméno+příjmení, číslo školy a e-mail) za účelem poskytnuti služby e-strava. Mé údaje budou uloženy pouze po nezbytně nutnou dobu, maximální však po dobu jednoho roku na serverech firmy Ulrich Software s.r.o. Údaje nebudou předány třetím stranám. Udělení souhlasu je dobrovolné a souhlas lze kdykoliv odvolat, a to napsáním emailu na adresu: info@ulrichsw.cz. Odvoláním souhlasu zůstane zákonnost předcházejícího zpracovaní údajů nedotčena. Dle článku 8. odst. 1 GDPR smí souhlas poskytnout pouze osoby starší 16 let. Je-li dítě mladší 16 let, musí byt tento souhlas vyjádřen nebo schválen zákonným zástupcem dítěte.",
        confirm: "Potvrzuji"
    },
    account: {
        action: {
            logout: "Odhlásit se",
            changePwd: "Změna hesla",
            changeEml: "Změna emailu",
            changeGdpr: "Odvolat",
            profiles: "Správa profilů"
        },
        profile: {
            actual: "Aktuální profil",
            heading: "Správa profilů",
            add: "Přidat další",
            info: "Dotykem přepněte do požadovaného profilu. Profil můžete také odstranit, nebo přidat další. Max. počet profilů je 10.",
            deleteConfirm: "Opravdu chcete smazat tento profil?",
            choose: "Zvolit"
        },
        email: {
            label: "Emailová adresa",
            heading: "Změnit email",
            original: "Původní adresa",
            new: "Nová adresa",
            confirm: "Potvrdit změnu",
            missing: "Emailová adresa u účtu není vyplněna, je nutné ji nyní doplnit, aby bylo možné aplikaci používat."
        },
        pwd: {
            heading: "Změnit heslo",
            original: "Staré heslo",
            pass1: "Nové heslo",
            pass2: "Nové heslo pro kontrolu",
            confirm: "Potvrdit změnu"
        },
        gdpr: {
            heading: "Odvolání GDPR souhlasu",
            message: "Odvoláním souhlasu Vám bude znemožněno až do jeho opětovného potvrzení používat webový portál i mobilní aplikaci eStrava. Pokud chcete opravdu souhlas odvolat potvrďte Vaši volbu tlačítkem níže. Na email Vám bude zasláno potvrzení o odvolání souhlasu a budete odhlášeni z aplikace.",
            confirm: "Odvolat"
        },
        jidelna: "Jídelna",
        user: "Strávník",
        gdprDate: "GDPR souhlas",
        changeConfirmation: "Změna byla úspěšně provedena"
    },
    zarizeni: {
        search: {
            placeholder: "Vyhledat",
            cancel: "Zrušit"
        },
        notAll: "Výběr jídelen je aktuálně omezen na vybraná školská zařízení"
    },
    info: {
        header: "Informace jídelny",
        buttons: {
            staticVersion: "Jídelníček s hodnocením",
            textVersion: "Detailní jídelníček",
            deadlines: "Časové omezení objednávání"
        },
        jidelnicek: {
            heading: "Jídelníček s hodnocením",
            week: "Týden"
        },
        jidelnicekText: {
            heading: "Detailní jídelníček"
        },
        deadlines: {
            heading: "Časové omezení objednávání",
            order: "Objednání je možné do ",
            sameDay: " hodin v daný den.",
            workingDay1: " hodin 1 pracovní den předem.",
            workingDayMore: " hodin [X] pracovní dny předem.",
            mondays: " na pondělí"
        }
    },
    finance: {
        header: "Finanční informace",
        send: {
            button: "Odeslat vyúčtování na email",            
            success: {
                header: "Odeslání vyúčtování",
                text: "Vyúčtování bylo odesláno na email [EMAIL]"
            }
        },
        original: {
            zaloha: "Záloha",
            odhlasky1: "Odhlášky řádné",
            odhlasky2: "Odhlášky dodatečné"
        }
    },  
    obdobi: {
        heading: "Změna měsíce",
        original: "Původní měsíc",
        new: "Nový měsíc",
        notavailable: "Další měsíc není dostupný"
    },
    jidelnicek: {  
        header: "Jídelníček",
        unorder: "ODHLÁŠKA",
        soup: "Polévka",
        lunch: "Obědy",
        breakfast: "Snídaně",
        pickup: "Výdejna",
        dinner: "Večeře",
        save: "Odeslat",
        saved: "Objednávka byla přijata a na email [EMAIL] Vám bylo zasláno potvrzení",
        savedrating: "Objednávka byla přijata a na email [EMAIL] Vám bylo zasláno potvrzení\n\nHodnocení jídla bylo uloženo.",
        savedratingonly: "Hodnocení jídla bylo uloženo.",
        collision: "Objednávka nebyla uložena! Došlo ke změně dat ze strany jídelny, ověřte aktuální stav a upravte svou objednávku.",
        nodata: "Nejste přihlášení k odběru stravy",
        reasonN: "Neobjednáno",
        reason9: "Dodatečně odhlášeno",
        allergens: {
            button: "Alergeny",
            heading:  'Alergeny',
            alergen1: 'Obiloviny obsahující lepek, konkrétně: 1.1 pšenice, 1.2 žito, 1.3 ječmen, 1.4 oves, 1.5 špalda, 1.6 kamut nebo jejich hybridní odrůdy a výrobky z nich',
            alergen2: 'Korýši a výrobky z nich.',
            alergen3: 'Vejce a výrobky z nich',
            alergen4: 'Ryby a výrobky z nich',
            alergen5: 'Arašídy (burské oříšky) a výrobky z nich',
            alergen6: 'Sója a výrobky z ní',
            alergen7: 'Kravské mléko a výrobky z něho',
            alergen8: 'Skořápkové plody, konkrétně: 8.1 mandle (Amygdalus communis L.), 8.2 lískové ořechy (Corylus avellana), 8.3 vlašské ořechy (Juglans regia), 8.4 kešu ořechy (Anacardium occidentale), 8.5 pekanové ořechy (Carya illinoinensis (Wangenh.) K. Koch), 8.6 para ořechy (Bertholletia excelsa), 8.7 pistácie (Pistacia vera), 8.8 makadamie (Macadamia ternifolia) a výrobky z nich',
            alergen9: 'Celer a výrobky z něho',
            alergen10: 'Hořčice a výrobky z ní',
            alergen11: 'Sezamová semena a výrobky z nich',
            alergen12: 'Oxid siřičitý a siřičitany (v koncentracích>10 mg/kg anebo  >10 mg/l, vyjádřeno SO2), sušené ovoce',
            alergen13: 'Vlčí bob(lupina) a výrobky z něj',
            alergen14: 'Měkkýši a výrobky z nich',
            alergen0: 'Neuvedeno',
            close: "Zavřít"
        },
        needSave: "Provedené změny je nutné odeslat!",
        inactive: "Jídelna nyní není v provozu, nelze provádět objednávky.",
        rating: {
            button: "Jak Vám chutnalo?",
            heading: "Hodnocení jídla",
            rating1: "Skvělé",
            rating2: "Lepší",
            rating3: "Dobré",
            rating4: "Ušlo to",
            rating5: "Nechutnalo",
            save: "Ohodnotit"
        },
        move: {
            up: "Nahoru",
            down: "Dolů"
        }
    },
    settings: {
        language: {
            header: "Jazyk aplikace"
        },
        settings: {
            header: "Nastavení zobrazení",
            onlynew: "Zobrazovat pouze budoucí dny",
            eaten: "Zobrazovat zda byla strava odebrána"
        }
    },
    navigator: {
        tabs: {
            menu: "Jídelníček ",
            info: "Informace ",
            finance: "Finance ",
            account: "Účet ",
            settings: "Nastavení ",
            about: "O aplikaci "
        },
        headers: {
            welcome: "Úvod",
            login: "Přihlášení",
            passwordRecovery: "Zapomenuté heslo",
            selection: "Výběr zařízení"
        }
    },
    error: {
        types: {
            entity_error: "Datová chyba",
            authentication_error: "Chyba přihlášení",
            application_error: "Aplikační chyba"
        },
        messages: {
            wrong_token: "Neplatný token",
            general_application_error: "Obecná aplikační chyba",
            missing_parameters: "Chybí vyplněná pole",
            wrong_credentials: "Chybné přihlašovací údaje",
            data_processing_error: "Chyba zpracování dat",
            missing_email: "Váš účet nemá  emailovou adresu, heslo nelze zaslat, obraťte se na vedoucí jídelny",
            wrong_email: "Zadaná emailová adresa nesouhlasí s uloženou, heslo nelze zaslat, obraťte se na vedoucí jídelny",
            wrong_original_password: "Staré heslo není správné",
            account_already_exists: "Pod stejným účtem jste již přihlášení, přepněte si profil."
        }
    },
    validation: {
        required: "Pole musí být vyplněno",
        email: "Email musí být vyplněn a v platném tvaru",
        passwordMatch: "Heslo musí být shodné"
    },
    confirmation: {
        yes: "Ano",
        no: "Ne"
    }
}