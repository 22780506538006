import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDispatch } from 'react-redux';
import { jidelnicekMakeRating } from '../../redux/actions/jidelnicek';
import AppButton from '../buttons/AppButton';
import ModalView from '../views/ModalView';

import { Star, StarOutline } from '@material-ui/icons';

const HodnoceniButton = (props) => {

  let [modalShown, setModalShown] = useState(false)
  let [rating, setRating] = useState(9)

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const saveRating = (prating) => {
    setModalShown(false)
    dispatch(jidelnicekMakeRating(props.den, props.jidlo.hodnota, prating))
  }

  return (
      <div className="mt-3">
        <AppButton sm label={t('jidelnicek.rating.button')} onPress={() => setModalShown(true)} />

        <ModalView visible={modalShown} handleHide={() => setModalShown(false)} heading={t('jidelnicek.rating.heading')} >
          <span className="wizard_text">{props.jidlo.nazev}</span>

          <div className="justify-content-between rating">
            {rating <= 5 ? <Star fontSize="large" color="warning" onClick={() => setRating(5)} /> : <StarOutline fontSize="large" color="warning" onClick={() => setRating(5)} />}
            {rating <= 4 ? <Star fontSize="large" color="warning" onClick={() => setRating(4)} /> : <StarOutline fontSize="large" color="warning" onClick={() => setRating(4)} />}
            {rating <= 3 ? <Star fontSize="large" color="warning" onClick={() => setRating(3)} /> : <StarOutline fontSize="large" color="warning" onClick={() => setRating(3)} />}
            {rating <= 2 ? <Star fontSize="large" color="warning" onClick={() => setRating(2)} /> : <StarOutline fontSize="large" color="warning" onClick={() => setRating(2)} />}
            {rating <= 1 ? <Star fontSize="large" color="warning" onClick={() => setRating(1)} /> : <StarOutline fontSize="large" color="warning" onClick={() => setRating(1)} />}
          </div>
          
          <AppButton label={t('jidelnicek.rating.save')} disabled={rating > 5} style={{marginTop: 20}} onPress={() => saveRating(rating)} />
      </ModalView>        
      </div>
  );

}

export default HodnoceniButton