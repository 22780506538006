import moment from 'moment'

const localizedDate = (date, format, lang) => {
    moment.locale(lang)
    return moment(date).format(format)
}

const formattedDate = (date, format) => {
    return moment(date).format(format)
}

const removeDiacritics = (val) => val.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase()

export {localizedDate, formattedDate, removeDiacritics}