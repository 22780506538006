import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router';
import AppButton from '../components/buttons/AppButton';
import LangSelectBox from '../components/items/LangSelectBox';

import MainView from '../components/views/MainView';
import sha1 from 'js-sha1';

const WelcomeScreen = ({ navigation }) => {
    const { t } = useTranslation();
    let history = useHistory();

    let {loggedIn, preselect, byCardZar} = useSelector(mapStateToProps)   

    return (
        <>
        {loggedIn ?
            <Redirect to="/jidelnicek" />
        :
        <MainView>
            {preselect && <Redirect to="/prihlaseni" />}
            {byCardZar && <Redirect to={"/prihlaseni-karta/"+byCardZar+"/"+sha1('karta'+byCardZar)} />}
            <div className="baseMarginView">
                <h2 className="wizard_header">{t('welcome.header')}</h2>
                <p className="wizard_text">{t('welcome.text1')}</p>

                <LangSelectBox style={{marginBottom: 12}} />

                <p className="wizard_text">{t('welcome.text2')}</p>
                
                <AppButton style={{marginBottom: 12}} label={t('welcome.choose')} onPress={() => history.push("/vyber-jidelny")}/>

                <p className="wizard_text">{t('welcome.text3')}</p>
            </div>

        </MainView>}
        </>
    )

}

const mapStateToProps = (state) => {
    return {
      loggedIn: state.auth.loggedIn,
      preselect: state.auth.preselect,
      byCardZar: state.auth.byCardZar
    }
  }

export default WelcomeScreen