import React from 'react';
import { Button, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import eStravaLogo from '../../assets/estrava_notext.svg'
import { jidelnicekResetObbodi } from '../../redux/actions/jidelnicek';
import { logout } from '../../redux/actions/stravnik';
import AppButton from '../buttons/AppButton';
import LangSelectBox from '../items/LangSelectBox';

const Header = ({isCardLogin}) => {
  
    let {loggedIn, activeAccount} = useSelector(mapStateToProps)    
    const { t } = useTranslation()

    const dispatch = useDispatch()

    return (
      <Navbar variant="light" bg="light" sticky="top" expand="lg">
      <Navbar.Brand>
        <img
          alt=""
          src={eStravaLogo}
          width="30"
          height="30"
          className="d-inline-block align-top"
        />{' '}
        eStrava
      </Navbar.Brand>
      <Navbar.Toggle />
      {loggedIn && activeAccount && <Navbar.Collapse className="justify-content-end">
        
        <Nav className="mr-auto">
            <Link to="/jidelnicek" className="nav-link">{t('navigator.tabs.menu')}</Link>
            <Link to="/informace" className="nav-link">{t('navigator.tabs.info')}</Link>
            {activeAccount && !activeAccount.bycard && <>
            <Link to="/finance" className="nav-link">{t('navigator.tabs.finance')}</Link>
            <Link to="/nastaveni" className="nav-link">{t('navigator.tabs.settings')}</Link>
            <Link to="/sprava-uctu" className="nav-link mr-3">{t('navigator.tabs.account')}</Link>
            <a href="https://secure.ulrichsw.cz/estrava/stara/" className="nav-link btn btn-dark text-white">{t('login.system.original')}</a>
            </>}
          </Nav>
          {activeAccount && activeAccount.bycard ? <>
          <Navbar.Text>
        {t('account.user')}: <strong>{activeAccount.user.jmeno+activeAccount.user.prijmeni+" ("+activeAccount.zarizeni.zarizeni+")"}</strong>
        </Navbar.Text>
        <AppButton style={{marginLeft: 15}} onPress={() => {
              dispatch(logout()).then(() => {
                dispatch(jidelnicekResetObbodi())
              }).catch(() => {})
            }} label={t('account.action.logout')} />
        </>
        :
        <>
          <Navbar.Text>
        {t('account.user')}:         
        </Navbar.Text>
        <NavDropdown title={activeAccount.user.jmeno+activeAccount.user.prijmeni+" ("+activeAccount.zarizeni.zarizeni+")"} alignRight flip>
            <Link to="/sprava-uctu" className="dropdown-item">{t('navigator.tabs.account')}</Link>
            <NavDropdown.Divider />
            <Button variant="link" className="dropdown-item" onClick={() => {
              dispatch(logout()).then(() => {
                dispatch(jidelnicekResetObbodi())
              }).catch(() => {})
            }}>{t('account.action.logout')}</Button>
        </NavDropdown>
        </>}
      
      </Navbar.Collapse>  }  
      {!loggedIn && !isCardLogin && <Navbar.Collapse className="justify-content-end">
            <LangSelectBox />
          <a href="https://secure.ulrichsw.cz/estrava/stara/" className="nav-link btn btn-dark text-white">{t('login.system.original')}</a>
          <Link to="/prihlaseni" className="nav-link">{t('login.form.send')}</Link>
      </Navbar.Collapse>  }  
      </Navbar>    
    )
  
}

const mapStateToProps = (state) => {
  return {
    loggedIn: state.auth.loggedIn,
    activeAccount: state.auth.actualAccount > -1 ? state.auth.accounts[state.auth.actualAccount] : null

  }
}

export default Header