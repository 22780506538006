
const ErrorMessageTypes = {
    entity_error: "error.types.entity_error",
    authentication_error: "error.types.authentication_error",
    application_error: "error.types.application_error"
}

const ErrorMessages = {
    wrong_token: "error.messages.wrong_token",
    general_application_error: "error.messages.general_application_error",
    missing_parameters: "error.messages.missing_parameters",
    wrong_credentials: "error.messages.wrong_credentials",
    data_processing_error: "error.messages.data_processing_error",
    missing_email: "error.messages.missing_email",
    wrong_email: "error.messages.wrong_email",
    wrong_original_password: "error.messages.wrong_original_password"
}

const showAlert = (statusMessage, t, replaceArr) => {
    let msg = t(statusMessage.message)
    if(replaceArr) {
        replaceArr.map((repl) => {
            msg = msg.replace(repl.search, repl.value)
            return repl
        })
    }
    alert(t(statusMessage.title) + "\n\n" + msg);
    //Alert.alert(t(statusMessage.title), msg);
}

const getMessage = (error, doNotLogout) => {
    let msg = {
        title: "",
        message: "",
        generalState: null
    }

    if (error.response === undefined || (error.response && error.response.status && error.response.status === 503)) {
        msg.generalState = "offline"
    } else {
        if (error.response && error.response.status && error.response.status === 401 && !doNotLogout) {
            msg.generalState = "notAuthorized"
        } else {
            if (error.response && error.response.data) {
                let payload = error.response.data
                if (payload.error) {
                    if (ErrorMessageTypes[payload.error.type]) {
                        msg.title = ErrorMessageTypes[payload.error.type]
                    }
                    if (ErrorMessages[payload.error.code]) {
                        msg.message = ErrorMessages[payload.error.code]
                    }
                }
                /*if (payload.message) {
                    msg.message += ': ' + payload.message
                }*/
                if (!msg.title) {
                    msg.title = ErrorMessageTypes['application_error']
                    if (!msg.message) {
                        msg.message = ErrorMessages['general_application_error']
                    }
                }
            } else {
                msg.title = ErrorMessageTypes['application_error']
                msg.message = ErrorMessages['general_application_error']
            }
            //Sentry.captureException(error);
        }
    }

    return msg
}

export {getMessage, showAlert};