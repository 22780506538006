import React, { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import AppButton from '../components/buttons/AppButton';
import Input from '../components/forms/Input';
import JidelnaBoxItem from '../components/items/JidelnaBoxItem';
import MainView from '../components/views/MainView';
import { jidelnaDetail } from '../redux/actions/jidelna';
import { login } from '../redux/actions/stravnik';
import { showAlert } from '../utils/errorMessages';

const LoginScreen = ({ route, navigation }) => {
    const { t } = useTranslation();
    const { register, formState: { isDirty, isValid, errors }, handleSubmit } = useForm({mode: 'onChange'});
    let { selzar } = useParams();
    //const { closeAfterLogin } = route.params
    const closeAfterLogin = false

    let history = useHistory();

    const dispatch = useDispatch();
    let {preselect, jidelnaSelected, accounts} = useSelector(mapStateToProps)

    useEffect(() => {
        if(selzar && (!jidelnaSelected.zarizeni || jidelnaSelected.zarizeni != selzar)) {
          dispatch(jidelnaDetail(selzar)).catch(() => {})
        }
        if(!selzar && preselect && !jidelnaSelected.zarizeni) {
            dispatch(jidelnaDetail(preselect.zarizeni)).catch(() => {})
        }
      }, [selzar, preselect, jidelnaSelected, dispatch]
    );

    const onSubmit = (data, jidelna) => {
        let existAccounts = accounts.filter((acc) => acc.user && acc.zarizeni && data.username === acc.user.username && jidelna.zarizeni === acc.zarizeni.zarizeni)
        if(existAccounts.length > 0) {
            showAlert({title: 'error.types.authentication_error', message: 'error.messages.account_already_exists'}, t)
        } else {
            dispatch(login({...data, kodzar: jidelna.zarizeni})).then(() => {
                history.push(closeAfterLogin ? "/sprava-uctu" : "/jidelnicek")
            }).catch((err) => {
                showAlert(err, t)
            })
        }
    }

    return (
        <MainView>
            <div className="baseMarginView">
                <h2 className="wizard_header">{t('navigator.headers.login')}</h2>

                <div className='alert alert-warning'>INFO pro účty @gmail.com: Aktuálně je problém s doručením emailu u Googlu. Je možné, že Vám nebudou doručovány potvrzení objednávek.</div>
                
                <span className="input_label">{t('login.selected')}</span>
                {jidelnaSelected && <JidelnaBoxItem data={jidelnaSelected} />}
                <AppButton style={{marginBottom: 12}} label={t('welcome.choose')} onPress={() => history.push("/vyber-jidelny")}/>

                <Form onSubmit={handleSubmit(onSubmit)} className="mt-3">
                    
                    <Input register={register} errors={errors} name="username" label={t('login.form.username')} rules={{required: true}} invalidMessage={t('validation.required')} />
                    <Input register={register} errors={errors} name="pass" secureTextEntry={true} label={t('login.form.pass')}  rules={{required: true}} invalidMessage={t('validation.required')}/>

                    <AppButton disabled={!isDirty || !isValid} style={{marginTop: 30}} onPress={handleSubmit((data) => onSubmit(data, jidelnaSelected))} label={t('login.form.send')} />
                </Form>

                <AppButton sm style={{marginTop: 30}} onPress={() => history.push("/zapomenute-heslo")} label={t('recovery.button')} />
            </div>
        </MainView>
    )

}

const mapStateToProps = (state) => {
    return {
        jidelnaSelected: state.jidelna.selected,
        accounts: state.auth.accounts,
        preselect: state.auth.preselect
    }
  }

export default LoginScreen