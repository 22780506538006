import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import AccountScreen from './screens/AccountScreen';
import DeadlineScreen from './screens/DeadlineScreen';
import FinInfoScreen from './screens/FinInfoScreen';
import InfoScreen from './screens/InfoScreen';
import JidelnicekStaticScreen from './screens/JidelnicekStaticScreen';
import JidelnicekTextScreen from './screens/JidelnicekTextScreen';
import JidlaScreen from './screens/JidlaScreen';
import LoginByCardScreen from './screens/LoginByCardScreen';
import LoginScreen from './screens/LoginScreen';
import PasswordRecoveryScreen from './screens/PasswordRecoveryScreen';
import ProfileScreen from './screens/ProfileScreen';
import SettingsScreen from './screens/SettingsScreen';
import WelcomeScreen from './screens/WelcomeScreen';
import ZarizeniSelectionScreen from './screens/ZarizeniSelectionScreen';

function App() {
  return (
    <BrowserRouter basename="/estrava">
      <Switch>
        <Route path="/" exact component={WelcomeScreen}/>
        <Route path="/vyber-jidelny" exact component={ZarizeniSelectionScreen}/>
        <Route path="/prihlaseni/:selzar" exact component={LoginScreen}/>
        <Route path="/prihlaseni" exact component={LoginScreen}/>
        <Route path="/prihlaseni-karta/:selzar/:hashcheck" exact component={LoginByCardScreen}/>
        <Route path="/zapomenute-heslo" exact component={PasswordRecoveryScreen}/>
        <Route path="/jidelnicek" exact component={JidlaScreen}/>
        <Route path="/jidelnicek-karta" exact component={JidlaScreen}/>
        <Route path="/jidelnicek-vypis" exact component={JidelnicekStaticScreen}/>
        <Route path="/jidelnicek-text" exact component={JidelnicekTextScreen}/>
        <Route path="/casove-omezeni" exact component={DeadlineScreen}/>
        <Route path="/sprava-uctu" exact component={AccountScreen}/>
        <Route path="/informace" exact component={InfoScreen}/>
        <Route path="/finance" exact component={FinInfoScreen}/>
        <Route path="/nastaveni" exact component={SettingsScreen}/>
        <Route path="/profily" exact component={ProfileScreen}/>
        <Redirect to="/" />
      </Switch>      
    </BrowserRouter>
  );
}

export default App;
