import React from 'react';
import { Check, Close } from '@material-ui/icons';

const OdebranoItem = (props) => {

  return (
    <>
    {props.odebrano ? 
    <Check className={"odebrano_ok"} fontSize="large"/>
    : 
    <Close className={"odebrano_nok"} fontSize="large" />
    }
  </>
  );

}

export default OdebranoItem