import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';

import MainView from '../components/views/MainView';
import { userInfo } from '../redux/actions/stravnik';
  
const DeadlineScreen = () => {

  const dispatch = useDispatch()
  let {activeAccount} = useSelector(mapStateToProps)

  const { t } = useTranslation();

  useEffect(() => {
      dispatch(userInfo())
  }, [activeAccount]);

  let deadlines = activeAccount && activeAccount.zarizeni && activeAccount.zarizeni.uzaverky

  let obedy = Object.keys(deadlines.obedy).map((key,index) => {
    return {...deadlines.obedy[key], num: key}
  })

  let obedy_pondeli = Object.keys(deadlines.obedy_pondeli).map((key,index) => {
    return {...deadlines.obedy_pondeli[key], num: key}
  })

  let vecere = Object.keys(deadlines.vecere).map((key,index) => {
    return {...deadlines.vecere[key], num: key}
  })

  return (
    <MainView loggedOnly={true}>
        <div className="baseMarginView">
          <h2 className="wizard_header">{t('info.buttons.deadlines')} </h2>
        </div>

        {deadlines && <>
      {deadlines.snidane && deadlines.snidane[1] && deadlines.snidane[1].cas !== "00:00" && <>
      <div>
          <span className="heading">{t('jidelnicek.breakfast')}</span>
        </div>
        <div className="basePaddingView baseBackground">
        <span className="wizard_text">
        {t('info.deadlines.order')}{deadlines.snidane[1].cas}
          {deadlines.snidane[1].den === 0 && t('info.deadlines.sameDay')}
          {deadlines.snidane[1].den === 1 && t('info.deadlines.workingDay1')}
          {deadlines.snidane[1].den > 1 && t('info.deadlines.workingDayMore')}
        </span>
        </div>
        </>}

        {deadlines.obedy && deadlines.obedy[1] && deadlines.obedy[1].cas !== "00:00" && <>
      <div>
          <span className="heading">{t('jidelnicek.lunch')}</span>
        </div>
        <div className="basePaddingView baseBackground">
        
        {obedy && obedy.map((ddln) => <div key={ddln.num}>
            {ddln.cas !== "00:00" && <>
              <span className="wizard_header">
                {ddln.num}
              </span>
              <span className="wizard_text" style={{marginLeft: 10}}>
                  {t('info.deadlines.order')}{ddln.cas}
                  {ddln.den === 0 && t('info.deadlines.sameDay')}
                  {ddln.den === 1 && t('info.deadlines.workingDay1')}
                  {ddln.den > 1 && t('info.deadlines.workingDayMore').replace('[X]', Math.abs(ddln.den))}
                </span>
            </>}
            </div>
        )}          
        </div>
        </>}

      {deadlines.obedy_pondeli && activeAccount.zarizeni.pondelky && deadlines.obedy_pondeli[1] && deadlines.obedy_pondeli[1].cas !== "00:00" && <>
      <div>
          <span className="heading">{t('jidelnicek.lunch')} {t('info.deadlines.mondays')}</span>
        </div>
        <div className="basePaddingView baseBackground">
        
        {obedy_pondeli && obedy_pondeli.map((ddln) => <div key={ddln.num}>
            {ddln.cas !== "00:00" && <>
              <span className="wizard_header">
                {ddln.num}
              </span>
              <span className="wizard_text" style={{marginLeft: 10}}>
                  {t('info.deadlines.order')}{ddln.cas}
                  {ddln.den === 0 && t('info.deadlines.sameDay')}
                  {ddln.den === 1 && t('info.deadlines.workingDay1')}
                  {ddln.den > 1 && t('info.deadlines.workingDayMore').replace('[X]', Math.abs(ddln.den))}
                </span>
            </>}
            </div>
        )}          
        </div>
        </>}

      {deadlines.vecere && deadlines.vecere[1] && deadlines.vecere[1].cas !== "00:00" && <>
      <div>
          <span className="heading">{t('jidelnicek.dinner')}</span>
        </div>
        <div className="basePaddingView baseBackground">
        
        {vecere && vecere.map((ddln) => <div key={ddln.num}>
            {ddln.cas !== "00:00" && <>
              <span className="wizard_header">
                {ddln.num}
              </span>
              <span className="wizard_text" style={{marginLeft: 10}}>
                  {t('info.deadlines.order')}{ddln.cas}
                  {ddln.den === 0 && t('info.deadlines.sameDay')}
                  {ddln.den === 1 && t('info.deadlines.workingDay1')}
                  {ddln.den > 1 && t('info.deadlines.workingDayMore').replace('[X]', Math.abs(ddln.den))}
                </span>
            </>}
            </div>
        )}          
        </div>
        </>}
      </>}

      <br />

    </MainView>
  )

}

const mapStateToProps = (state) => {
return {
    activeAccount: state.auth.actualAccount > -1 ? state.auth.accounts[state.auth.actualAccount] : null
}
}

export default DeadlineScreen