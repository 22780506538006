import React from 'react';
import { Col, Row } from 'react-bootstrap';

const JidelnaBoxItem = (props) => {

  return (
      <div className="list_item">
        <Row>
          <Col sm={9}>
            <span className="list_label list_label_big">{props.data.mesto}</span><br />
            <span className="list_label">{props.data.nazev}</span>
            </Col>
            <Col sm={3} className="text-right">
            <span className="list_label">{props.data.zarizeni}</span>
            </Col>
          </Row>
      </div>

  );

}

export default JidelnaBoxItem