import { combineReducers } from 'redux';
import authReducer from "./auth";
import jidelna from './jidelna';
import jidelnicek from './jidelnicek';
import stravnik from './stravnik';

export default combineReducers({
  auth: authReducer,
  jidelna: jidelna,
  jidelnicek: jidelnicek,
  stravnik: stravnik
});