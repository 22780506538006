import React from 'react';
import { Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AppButton from '../components/buttons/AppButton';
import Input from '../components/forms/Input';
import JidelnaBoxItem from '../components/items/JidelnaBoxItem';
import MainView from '../components/views/MainView';
import { recoverPassword } from '../redux/actions/stravnik';
import { showAlert } from '../utils/errorMessages';

const PasswordRecoveryScreen = () => {
    const { t } = useTranslation();
    const { register, formState: { isDirty, isValid, errors }, handleSubmit } = useForm({mode: 'onChange'});

    const dispatch = useDispatch();
    let {jidelnaSelected} = useSelector(mapStateToProps)

    let history = useHistory();

    const onSubmit = (data, jidelna) => {
        dispatch(recoverPassword({...data, kodzar: jidelna.zarizeni})).then(() => {
            showAlert({title: 'navigator.headers.passwordRecovery', message: 'recovery.confirm'}, t)
            history.push("/prihlaseni")
        }).catch((err) => {
            showAlert(err, t)
        })
    }

    return (
        <MainView>
            <div className="baseMarginView">
                <span className="input_label">{t('login.selected')}</span>
                {jidelnaSelected && <JidelnaBoxItem data={jidelnaSelected} />}

                <Form onSubmit={handleSubmit(onSubmit)} className="mt-3">
                    
                    <Input register={register} errors={errors} name="username" label={t('login.form.username')} rules={{required: true}} invalidMessage={t('validation.required')}/>
                    <Input register={register} errors={errors} name="email" label={t('recovery.email')}  rules={{required: true}} invalidMessage={t('validation.required')}/>

                    <AppButton disabled={!isDirty || !isValid} style={{marginTop: 30}} onPress={handleSubmit((data) => onSubmit(data, jidelnaSelected))} label={t('recovery.button')} />
                </Form>

            </div>
        </MainView>        
    )

}

const mapStateToProps = (state) => {
    return {
        jidelnaSelected: state.jidelna.selected
    }
  }

export default PasswordRecoveryScreen