import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';
import AppButton from '../components/buttons/AppButton';
import ObdobiSelect from '../components/forms/ObdobiSelect';
import AllergenItem from '../components/items/AllergenItem';
import JidelnicekItem from '../components/items/JidelnicekItem';

import MainView from '../components/views/MainView';
import ModalView from '../components/views/ModalView';
import { jidelnicekList, jidelnicekObjednat, jidelnicekToggleAllergenModal } from '../redux/actions/jidelnicek';

import { WarningOutlined, Refresh } from '@material-ui/icons';
import { ButtonGroup, Col, Navbar, Overlay, Tooltip } from 'react-bootstrap';
import { showAlert } from '../utils/errorMessages';
  
const JidlaScreen = () => {

    const dispatch = useDispatch()
    let {jidlaAll, jidlaHash, jidlaExpiration, jidlaRating, selObd, obdobi, obdobiLoaded, activeAccount, alergenModalShown} = useSelector(mapStateToProps)    
    const { t } = useTranslation()

    let [refreshing, setIsRefreshing] = useState(false)

    const jidlaExpirationRef = useRef(jidlaExpiration);
    const selObdRef = useRef(selObd);
    const jidlaSaveAlertRef = useRef(null);

    const onRefresh = () => {
        setIsRefreshing(true);
        dispatch(jidelnicekList(selObdRef.current)).then(() => {
          setIsRefreshing(false)
        }).catch(() => {
          setIsRefreshing(false)
        })
    }

    useEffect(() => {
      jidlaExpirationRef.current = jidlaExpiration
    }, [jidlaExpiration]);
    
    useEffect(() => {
        selObdRef.current = selObd
        if(selObd && selObd.r && selObd.m) {
          dispatch(jidelnicekList(selObd)).catch(() => {})
        }
      }, [selObd, dispatch]
    );

    let jidla = jidlaAll && jidlaAll.filter((jid) => activeAccount && activeAccount.user && (!activeAccount.user.zobrnove || moment(jid.datum).isSameOrAfter(moment(), 'day')))

    //mame nejakou zmenu oproti predchozimu stavu?
    let jidlaOnlyChanged = jidla.filter((den) => {
      let snidaneChanged = den.snidane.data.filter((snid) => snid.objednano !== snid.selected)
      let obedyChanged = den.obedy.data.filter((obed) => obed.objednano !== obed.selected && obed.hodnota !== null)
      let vecereChanged = den.vecere.data.filter((vece) => vece.objednano !== vece.selected)
      return (snidaneChanged.length > 0 || obedyChanged.length > 0 || vecereChanged.length > 0)
    }).length > 0 
    let jidlaChanged = jidlaOnlyChanged || jidlaRating

    let showOdebrane = (activeAccount && activeAccount.user && activeAccount.zarizeni && activeAccount.user.odebrane && activeAccount.zarizeni.odebrane)
    let isActive = (activeAccount && activeAccount.zarizeni && activeAccount.zarizeni.viditelnost === 'A')

    return (
      <MainView loggedOnly={true}>
          <div className="baseMarginView">
          <h2 className="wizard_header">{t('navigator.tabs.menu')} </h2>
          <Navbar className="mb-4 px-0 jidelnicek_top_bar" sticky="top" style={{top:56}} expand="sm">
                <Col xs={12} sm={8} className="px-0">
                  <ButtonGroup>
                  <ObdobiSelect/>
                  
                  <AppButton style={{margin: 0}} onPress={() => {
                    onRefresh();
                  }} >
                    <Refresh fontSize="small" />
                  </AppButton>

                  </ButtonGroup>      
                  <AppButton style={{marginLeft: 5}} variant="warning" onPress={() => {
                      dispatch(jidelnicekToggleAllergenModal())
                      }} label={t('jidelnicek.allergens.button')}>
                      <WarningOutlined style={{marginRight: 8}} fontSize="small" />
                  </AppButton>

                </Col>

                <Col xs={12} sm={4} className="px-0 text-right" >
                    
                <AppButton fref={jidlaSaveAlertRef} disabled={!jidlaChanged} onPress={() => {
                dispatch(jidelnicekObjednat(selObd, jidlaHash, jidla, jidlaRating, jidlaOnlyChanged)).then((result) => {
                  dispatch(jidelnicekList(selObd))
                  showAlert({title: 'jidelnicek.header', message: result.hadRating ? ( result.hadJidla ? 'jidelnicek.savedrating' : 'jidelnicek.savedratingonly') : 'jidelnicek.saved'}, t, [{search: '[EMAIL]', value: activeAccount && activeAccount.user && activeAccount.user.email}])
                }).catch(() => {
                  dispatch(jidelnicekList(selObd))
                  showAlert({title: 'jidelnicek.header', message: 'jidelnicek.collision'}, t)
                })
              }} label={t('jidelnicek.save')}/>   

                <Overlay target={jidlaSaveAlertRef.current} show={jidlaChanged} placement="bottom">
                  {(props) => (
                    <Tooltip id="jidla-tooltip" {...props}>
                      {t('jidelnicek.needSave')}
                    </Tooltip>
                  )}
                </Overlay>
                </Col>

            </Navbar>

              {isActive && jidla && jidla.length > 0 && jidla.map((item) => <JidelnicekItem bigger={activeAccount.bycard} key={item.datum} data={item} odebrane={showOdebrane} />)}

        {obdobiLoaded && obdobi.length === 0 &&
                    <div className="centeredView basePaddingView">
            <span className="wizard_text danger">{t('jidelnicek.nodata')}</span>
          </div>}
        {!isActive && 
          <div className="centeredView basePaddingView">
            <span className="wizard_text danger">{t('jidelnicek.inactive')}</span>
          </div>}

        <ModalView visible={alergenModalShown} handleHide={() => dispatch(jidelnicekToggleAllergenModal())} heading={t('jidelnicek.allergens.heading')}>
              <AllergenItem num={1} t={t}/>
              <AllergenItem num={2} t={t}/>
              <AllergenItem num={3} t={t}/>
              <AllergenItem num={4} t={t}/>
              <AllergenItem num={5} t={t}/>
              <AllergenItem num={6} t={t}/>
              <AllergenItem num={7} t={t}/>
              <AllergenItem num={8} t={t}/>
              <AllergenItem num={9} t={t}/>
              <AllergenItem num={10} t={t}/>
              <AllergenItem num={11} t={t}/>
              <AllergenItem num={12} t={t}/>
              <AllergenItem num={0} t={t}/>
            <AppButton sm onPress={() => dispatch(jidelnicekToggleAllergenModal())} label={t('jidelnicek.allergens.close')} />
        </ModalView>
        
          </div>
      </MainView>
    )
  
}

const mapStateToProps = (state) => {
  return {
      jidlaAll: state.jidelnicek.list,
      jidlaExpiration: state.jidelnicek.listExpiration,
      jidlaHash: state.jidelnicek.listHash,
      jidlaRating: state.jidelnicek.rating,
      selObd: state.jidelnicek.selectedObdobi,
      obdobi: state.jidelnicek.obdobi,
      obdobiLoaded: state.jidelnicek.obdobiLoaded,
      alergenModalShown: state.jidelnicek.alergenModalShown,
      activeAccount: state.auth.actualAccount > -1 ? state.auth.accounts[state.auth.actualAccount] : null
  }
}

export default JidlaScreen