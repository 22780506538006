import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Redirect } from 'react-router';

import { useSelector } from 'react-redux';
import { JIDELNA_LIST_INPROGRESS } from '../../redux/actions/jidelna';
import { JIDELNICEK_LIST_INPROGRESS, JIDELNICEK_OBDOBI_INPROGRESS, JIDELNICEK_SAVE_INPROGRESS } from '../../redux/actions/jidelnicek';
import { STRAVNIK_CHANGE_EMAIL_INPROGRESS, STRAVNIK_CHANGE_PWD_INPROGRESS, STRAVNIK_FINANCE_INPROGRESS, STRAVNIK_GDPR_INPROGRESS, STRAVNIK_LOGIN_INPROGRESS, STRAVNIK_RECOVER_PWD_INPROGRESS } from '../../redux/actions/stravnik';

import ActivityOverlayView from './ActivityOverlayView';
import Header from './Header';
import AppButton from '../buttons/AppButton';
import { ArrowUpward, ArrowDownward } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

const MainView = (props) => {
  
    let {jidelnicekStatus, jidelnaStatus, authStatus, stravnikStatus, loggedIn, activeAccount} = useSelector(mapStateToProps)    
    const { t } = useTranslation()

    let isLoading = (
      jidelnicekStatus === JIDELNICEK_LIST_INPROGRESS || 
      jidelnicekStatus === JIDELNICEK_SAVE_INPROGRESS || 
      jidelnicekStatus === JIDELNICEK_OBDOBI_INPROGRESS || 
      jidelnaStatus === JIDELNA_LIST_INPROGRESS ||
      authStatus === STRAVNIK_LOGIN_INPROGRESS ||
      authStatus === STRAVNIK_GDPR_INPROGRESS ||
      authStatus === STRAVNIK_CHANGE_EMAIL_INPROGRESS ||
      authStatus === STRAVNIK_CHANGE_PWD_INPROGRESS ||
      authStatus === STRAVNIK_RECOVER_PWD_INPROGRESS ||
      stravnikStatus === STRAVNIK_FINANCE_INPROGRESS
    )
    return (
      <>
      {!loggedIn && props.loggedOnly ? <Redirect to="/" /> : <>
      <Header isCardLogin={props.isCardLogin}/>
      <Container fluid="md">
        <Row>
          <Col className="lightBackground">
            {props.children}
          </Col>
        </Row>
      </Container>
      {isLoading && <ActivityOverlayView/>}
      {activeAccount && activeAccount.bycard && 
        <div className="bottomScrollContainer">
          <AppButton onPress={() => {window.scrollBy(0,-350);}} label={t("jidelnicek.move.up")}>
            <ArrowUpward fontSize="small" style={{marginRight: 4}}/>
          </AppButton>
          <AppButton onPress={() => {window.scrollBy(0,350);}} label={t("jidelnicek.move.down")}>
          <ArrowDownward fontSize="small" style={{marginRight: 4}}/>
          </AppButton>
        </div>}
      </>}
      </>
    )
  
}

const mapStateToProps = (state) => {
  return {
      loggedIn: state.auth.loggedIn,
      jidelnicekStatus: state.jidelnicek.status,
      jidelnaStatus: state.jidelna.status,
      authStatus: state.auth.status,
      stravnikStatus: state.stravnik.status,
      activeAccount: state.auth.actualAccount > -1 ? state.auth.accounts[state.auth.actualAccount] : null
  }
}

export default MainView