import config from '../../utils/config'
import api from '../../utils/axios'
import {getMessage} from "../../utils/errorMessages";

export const JIDELNICEK_OBDOBI = 'JIDELNICEK_OBDOBI'
export const JIDELNICEK_LIST = 'JIDELNICEK_LIST'
export const JIDELNICEK_SAVE = 'JIDELNICEK_SAVE'
export const JIDELNICEK_STATIC_LIST = 'JIDELNICEK_STATIC_LIST'

export const JIDELNICEK_OBDOBI_INPROGRESS = 'JIDELNICEK_OBDOBI_INPROGRESS'
export const JIDELNICEK_OBDOBI_OK = 'JIDELNICEK_OBDOBI_OK'
export const JIDELNICEK_OBDOBI_ERROR = 'JIDELNICEK_OBDOBI_ERROR'

export const JIDELNICEK_LIST_INPROGRESS = 'JIDELNICEK_LIST_INPROGRESS'
export const JIDELNICEK_LIST_OK = 'JIDELNICEK_LIST_OK'
export const JIDELNICEK_LIST_ERROR = 'JIDELNICEK_LIST_ERROR'

export const JIDELNICEK_STATIC_LIST_INPROGRESS = 'JIDELNICEK_STATIC_LIST_INPROGRESS'
export const JIDELNICEK_STATIC_LIST_OK = 'JIDELNICEK_STATIC_LIST_OK'
export const JIDELNICEK_STATIC_LIST_ERROR = 'JIDELNICEK_STATIC_LIST_ERROR'

export const JIDELNICEK_SAVE_INPROGRESS = 'JIDELNICEK_SAVE_INPROGRESS'
export const JIDELNICEK_SAVE_OK = 'JIDELNICEK_SAVE_OK'
export const JIDELNICEK_SAVE_ERROR = 'JIDELNICEK_SAVE_ERROR'

export const JIDELNICEK_SELECT_OBDOBI = 'JIDELNICEK_SELECT_OBDOBI'
export const JIDELNICEK_RESET_OBDOBI = 'JIDELNICEK_RESET_OBDOBI'
export const JIDELNICEK_SELECT_JIDLO = 'JIDELNICEK_SELECT_JIDLO'
export const JIDELNICEK_TOGGLE_ALLERGENS = 'JIDELNICEK_TOGGLE_ALLERGENS'
export const JIDELNICEK_SET_RATING = 'JIDELNICEK_SET_RATING'

export const getObdobi = () => (dispatch, getState) =>
    new Promise(function (resolve, reject) {
        dispatch({
            type: JIDELNICEK_OBDOBI,
            status: JIDELNICEK_OBDOBI_INPROGRESS
        })

        api(getState).get('/', {params: {v: config.versionAPI, action: 'selobd'}}).then((payload) => {
            resolve()
            dispatch({
                type: JIDELNICEK_OBDOBI,
                status: JIDELNICEK_OBDOBI_OK,
                data: payload.data
            })
        }).catch((error) => {
            let err = getMessage(error, false)
            reject(err)
            dispatch({
                type: JIDELNICEK_OBDOBI,
                status: JIDELNICEK_OBDOBI_ERROR,
                statusMessage: err
            })
        })

    })

export const jidelnicekList = (obdobi) => (dispatch, getState) =>
    new Promise(function (resolve, reject) {
        dispatch({
            type: JIDELNICEK_LIST,
            status: JIDELNICEK_LIST_INPROGRESS
        })
        api(getState).get('/', {params: {v: config.versionAPI, action: 'jidla', ...obdobi}}).then((payload) => {
            resolve()
            dispatch({
                type: JIDELNICEK_LIST,
                status: JIDELNICEK_LIST_OK,
                data: payload.data
            })
        }).catch((error) => {
            reject(error)
            dispatch({
                type: JIDELNICEK_LIST,
                status: JIDELNICEK_LIST_ERROR,
                statusMessage: getMessage(error, false)
            })
        })
    })

export const jidelnicekStaticList = () => (dispatch, getState) =>
    new Promise(function (resolve, reject) {
        dispatch({
            type: JIDELNICEK_STATIC_LIST,
            status: JIDELNICEK_STATIC_LIST_INPROGRESS
        })
        api(getState).get('/', {params: {v: config.versionAPI, action: 'jidelnicek'}}).then((payload) => {
            resolve()
            dispatch({
                type: JIDELNICEK_STATIC_LIST,
                status: JIDELNICEK_STATIC_LIST_OK,
                data: payload.data
            })
        }).catch((error) => {
            reject(error)
            dispatch({
                type: JIDELNICEK_STATIC_LIST,
                status: JIDELNICEK_STATIC_LIST_ERROR,
                statusMessage: getMessage(error, false)
            })
        })
    })    

export const jidelnicekObjednat = (obdobi, hash, actData, rating, jidlaChanged) => (dispatch, getState) =>
    new Promise(function (resolve, reject) {
        dispatch({
            type: JIDELNICEK_SAVE,
            status: JIDELNICEK_SAVE_INPROGRESS
        })
        let insData = {
            snidane: [],
            obedy: [],
            vecere: []
        }
        actData.map((day) => {
            if(day.obedy && day.obedy.data) {
                let puvodni = day.obedy.data.filter((jid) => jid.objednano === true)
                let selected = day.obedy.data.filter((jid) => jid.selected === true)
                if(selected && selected.length > 0 && puvodni && puvodni.length > 0) {
                    insData.obedy.push({den: day.den, hodnota: selected[0].hodnota, puvodni: puvodni[0].hodnota})
                }
            }
            if(day.snidane && day.snidane.data) {
                let puvodni = day.snidane.data.filter((jid) => jid.objednano === true)
                let selected = day.snidane.data.filter((jid) => jid.selected === true)
                if(selected && selected.length > 0 && puvodni && puvodni.length > 0) {
                    insData.snidane.push({den: day.den, hodnota: selected[0].hodnota, puvodni: puvodni[0].hodnota})
                }
            }
            if(day.vecere && day.vecere.data) {
                let puvodni = day.vecere.data.filter((jid) => jid.objednano === true)
                let selected = day.vecere.data.filter((jid) => jid.selected === true)
                if(selected && selected.length > 0 && puvodni && puvodni.length > 0) {
                    insData.vecere.push({den: day.den, hodnota: selected[0].hodnota, puvodni: puvodni[0].hodnota})
                }
            }                        
            return day
        })
        if(rating) {
            insData.hodnoceni = rating
        }
        api(getState).post('/', {hashcheck: hash, ...insData}, {params: {v: config.versionAPI, action: 'objednat', ...obdobi}}).then((payload) => {
            resolve({hadRating: rating, hadJidla: jidlaChanged})
            dispatch({
                type: JIDELNICEK_SAVE,
                status: JIDELNICEK_SAVE_OK,
                data: payload.data
            })
        }).catch((error) => {
            reject(error)
            dispatch({
                type: JIDELNICEK_SAVE,
                status: JIDELNICEK_SAVE_ERROR,
                statusMessage: getMessage(error, false)
            })
        })
    })

export const jidelnicekSelectObbodi = (obdobi) => {
    return (dispatch) => {
        dispatch({
            type: JIDELNICEK_SELECT_OBDOBI,
            data: obdobi
        })
    }
}

export const jidelnicekResetObbodi = (obdobi) => {
    return (dispatch) => {
        dispatch({
            type: JIDELNICEK_RESET_OBDOBI
        })
    }
}

export const jidelnicekToggleAllergenModal = () => {
    return (dispatch) => {
        dispatch({
            type: JIDELNICEK_TOGGLE_ALLERGENS
        })
    }
}

export const jidelnicekSelectJidlo = (datum, typ, hodnota) => {
    return (dispatch) => {
        dispatch({
            type: JIDELNICEK_SELECT_JIDLO,
            data: {
                typ: typ,
                datum: datum,
                hodnota: hodnota
            }
        })
    }
}

export const jidelnicekMakeRating = (den, cislo, znamka) => {
    return (dispatch) => {
        dispatch({
            type: JIDELNICEK_SET_RATING,
            data: {
                den,
                cislo,
                znamka
            }
        })
    }
}