export default {
    welcome: {
        header: "Vitajte v aplikácii eStrava",
        text1: "Zvoľte si jazyk aplikácie",
        text2: "Pokračujte výberom zariadenia/jedálne ku ktorej sa chcete prihlásiť",
        text3: "V ďalšom kroku budete vyzvaní na prihlásenie k vybranému zariadeniu.",
        choose: "Vybrať jedáleň"
    },    
    login: {
        selected: "Vybraná jedáleň",
        form: {
            username: "Kód stravníka",
            pass: "Heslo",
            send: "Prihlásiť"
        },
        card: {
            heading: "Priložte čip"            
        },
        system: {
            original: "Pôvodná eStrava"
        }
    },
    recovery: {
        email: "Aktuálna emailová adresa",
        button: "Zaslať zabudnuté heslo",
        confirm: "Na uvedenú emailovú adresu bolo zaslané nové prístupové heslo"
    },
    gdpr: {
        header: "Súhlas so spracovaním osobných údajov",
        text: "Týmto udeľujem firme Ulrich Software, s.r.o. súhlas so spracovaním mojich osobných údajov (iniciály, meno+priezvisko, číslo školy, a e-mail) na účely spojené so službou e-strava. Moje údaje budú uložené iba na nevyhnutné časové obdobie, ale maximálne jeden rok na serveroch Ulrich Software s.r.o. Údaje nebudú poskytnuté tretím stranám. Súhlas je dobrovoľný a je ho možné kedykoľvek odvolať napísaním e-mailu na adresu: info@ulrichsw.cz. Zrušenie súhlasu nebude mať vplyv na zákonnosť predchádzajúceho spracovania údajov. Podľa článku 8. odst. 1 GDPR súhlas môžu poskytnúť iba osoby staršie ako 16 rokov. Ak je dieťa mladšie ako 16 rokov, súhlas musí vyjadriť alebo schváliť zákonný zástupca dieťaťa.",
        confirm: "Potvrdzujem"
    },
    account: {
        action: {
            logout: "Odhlásiť sa",
            changePwd: "Zmena hesla",
            changeEml: "Zmena emailu",
            changeGdpr: "Odvolať",
            profiles: "Správa profilov"
        },
        profile: {
            actual: "Aktuálny profil",
            heading: "Správa profilov",
            add: "Pridať ďalšie",
            info: "Dotykom prepnite do požadovaného profilu. Profil môžete tiež odstrániť, alebo pridať ďalšie. Max. počet profilov je 10.",
            deleteConfirm: "Naozaj chcete zmazať tento profil?",
            choose: "Zvoliť"
        },        
        email: {
            label: "Emailová adresa",
            heading: "Zmeniť email",
            original: "Pôvodná adresa",
            new: "Nová adresa",
            confirm: "Potvrdiť zmenu",
            missing: "Emailová adresa pri účte nie je vyplnená, je nutné ju teraz doplniť, aby bolo možné aplikáciu používať."
        },
        pwd: {
            heading: "Zmeniť heslo",
            original: "Staré heslo",
            pass1: "Nové heslo",
            pass2: "Nové heslo pre kontrolu",
            confirm: "Potvrdiť zmenu"
        },
        gdpr: {
            heading: "Odvolanie GDPR súhlasu",
            message: "Odvolaním súhlasu Vám bude znemožnené až do jeho opätovného potvrdenia používať webový portál aj mobilnú aplikáciu eStrava. Ak chcete naozaj súhlas odvolať potvrďte Vašu voľbu tlačidlom nižšie. Na email Vám bude zaslané potvrdenie o odvolaniu súhlasu a budete odhlásený z aplikácie.",
            confirm: "Odvolať"
        },
        jidelna: "Jedáleň",
        user: "Stravník",
        gdprDate: "GDPR súhlas",
        changeConfirmation: "Zmena bola úspešne vykonaná"
    },
    zarizeni: {
        search: {
            placeholder: "Vyhľadať",
            cancel: "Zrušiť"
        },
        notAll: "Výber jedální je v súčasnosti obmedzený na vybrané školské zariadenia"
    },
    info: {
        header: "Informácie jedálne",
        buttons: {
            staticVersion: "Jedálny lístok s hodnotením",
            textVersion: "Detailný jedálny lístok",
            deadlines: "Časové obmedzenie objednávania"
        },
        jidelnicek: {
            heading: "Jedálny lístok s hodnotením",
            week: "Týždeň"
        },
        jidelnicekText: {
            heading: "Detailný jedálny lístok"
        },
        deadlines: {
            heading: "Časové obmedzenie objednávania",
            order: "Objednanie je možné do ",
            sameDay: " hodín v daný deň.",
            workingDay1: " hodín 1 pracovný deň vopred.",
            workingDayMore: " hodín [X] pracovné dni vopred.",
            mondays: " na pondelok"
        }
    },
    finance: {
        header: "Finančné informácie",
        send: {
            button: "Odoslať vyúčtovanie na email",            
            success: {
                header: "Odoslanie vyúčtovania",
                text: "Vyúčtovanie bolo odoslané na email [EMAIL]"
            }
        },
        original: {
            zaloha: "Záloha",
            odhlasky1: "Odhlášky riadne",
            odhlasky2: "Odhlášky dodatočné"
        }
    },
    obdobi: {
        heading: "Zmena mesiaca",
        original: "Pôvodný mesiac",
        new: "Nový mesiac",
        notavailable: "Ďalší mesiac nie je dostupný"
    },        
    jidelnicek: {
        header: "Jedálny lístok",
        unorder: "ODHLÁŠKA",
        soup: "Polievka",
        lunch: "Obedy",
        breakfast: "Desiata",
        pickup: "Výdajna",
        dinner: "Večere",
        save: "Odoslať",
        saved: "Objednávka bola prijatá a na email [EMAIL] Vám bolo zaslané potvrdenie",
        savedrating: "Objednávka bola prijatá a na email [EMAIL] Vám bolo zaslané potvrdenie\n\nHodnotenie jedla bolo uložené.",
        savedratingonly: "Hodnotenie jedla bolo uložené.",
        collision: "Objednávka nebola uložená! Došlo k zmene údajov zo strany jedálne, overte aktuálny stav a upravte svoju objednávku.",
        nodata: "Nie ste prihlásený na odber stravy",
        reasonN: "Neobjednané",
        reason9: "Dodatočne odhlásené",
        allergens: {
            button: "Alergeny",
            heading:  'Alergeny',
            alergen1: 'Obilniny obsahujúce lepok (t.j. pšenica, raž, jačmeň, ovos) a výrobky z nich',
            alergen2: 'Kôrovce a výrobky z nich',
            alergen3: 'Vajcia  a výrobky z nich',
            alergen4: 'Ryby a výrobky z nich',
            alergen5: 'Arašidy a výrobky s nich',
            alergen6: 'Sójové zrná a výrobky z nich',
            alergen7: 'Mlieko a výrobky z neho',
            alergen8: 'Orechy a výrobky z nich',
            alergen9: 'Zeler a výrobky z neho',
            alergen10: 'Horčica a výrobky z nej',
            alergen11: 'Sezamové semená a výrobky z nich',
            alergen12: 'Oxid siričitý a siričitany (>10 mg/kg alebo  >10 mg/l, sušené ovocie, ovocné konzervy, výrobky zo',
            alergen13: 'Vlčí bôb a výrobky z neho',
            alergen14: 'Mäkkýše a výrobky z nich',
            alergen0: 'Neuvedeno',
            close: "Zatvoriť"
        },
        needSave: "Vykonané zmeny je treba odoslať!",
        inactive: "Jedáleň teraz nie je v prevádzke, nemôžu byť vykonávané objednávky.",
        rating: {
            button: "Ako Vám chutilo?",
            heading: "Hodnotenie jedla",
            rating1: "Výborné",
            rating2: "Lepšie",
            rating3: "Dobré",
            rating4: "Dalo sa zjesť",
            rating5: "Nechutilo",
            save: "Ohodnotiť"
        },
        move: {
            up: "Hore",
            down: "Nadol"
        }
    },
    settings: {
        language: {
            header: "Jazyk aplikácie"
        },
        settings: {
            header: "Nastavenie zobrazenia",
            onlynew: "Zobrazovať iba budúce dni",
            eaten: "Zobrazovať či bola strava odobraná"
        }
    },
    navigator: {
        tabs: {
            menu: "Menu ",
            info: "Informacie ",
            finance: "Financie ",
            account: "Účet ",
            settings: "Nastavenie ",
            about: "O aplikácii "
        },
        headers: {
            welcome: "Úvod",
            login: "Prihlásenie",
            passwordRecovery: "Zabudnuté heslo",
            selection: "Výber zariadenia"
        }
    },
    error: {
        types: {
            entity_error: "Dátová chyba",
            authentication_error: "Chyba prihlásenia",
            application_error: "Aplikačná chyba"
        },
        messages: {
            wrong_token: "Neplatný token",
            general_application_error: "Všeobecná aplikačná chyba",
            missing_parameters: "Chýbajú vyplnené polia",
            wrong_credentials: "Chybné prihlasovacie údaje",
            data_processing_error: "Chyba spracovania dát",
            missing_email: "Váš účet nemá emailovú adresu, heslo nie je možné poslať, kontaktujte vedúcu",
            wrong_email: "Zadaná emailová adresa nesúhlasí s uloženou, heslo nie je možné zaslať, obráťte sa na vedúcu jedálne",
            wrong_original_password: "Staré heslo nie je správne",
            account_already_exists: "Pod rovnakým účtom ste už prihlásení, prepnite si profil."
        }
    },
    validation: {
        required: "Pole musí byť vyplnené",
        email: "Email musí byť vyplnený a v platnom tvare",
        passwordMatch: "Heslo musí byť zhodné"
    },
    confirmation: {
        yes: "Áno",
        no: "Nie"
    }
}