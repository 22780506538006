import React from 'react';

const BoxItem = (props) => {

  return (
    <div className="list_item">
      <span className="list_label">{props.value}</span>
    </div>
  );

}

export default BoxItem