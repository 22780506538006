import { JIDELNA_SELECT } from "../actions/jidelna";
import { STRAVNIK_AUTH_SET_LANG, STRAVNIK_CHANGE_EMAIL, STRAVNIK_CHANGE_EMAIL_OK, STRAVNIK_GDPR, STRAVNIK_GDPR_OK, STRAVNIK_INFO, STRAVNIK_INFO_OK, STRAVNIK_LOGIN, STRAVNIK_LOGIN_OK, STRAVNIK_LOGOUT, STRAVNIK_SETTINGS, STRAVNIK_SETTINGS_OK, STRAVNIK_SET_ACTIVE_ACCOUNT } from "../actions/stravnik";

const iniState = {
    status: null,
    statusMessage: "",
    systemStatus: "",
    loggedIn: false,
    accounts: [],
    actualAccount: -1,
    lang: null,
    preselect: {},
    byCardZar: null
}
export default (state = iniState, action) => {
    switch (action.type) {
        case STRAVNIK_LOGIN:
            if (action.status === STRAVNIK_LOGIN_OK) {
                let newAccounts = [...state.accounts]
                newAccounts.push(action.data)
                return {
                    ...state,
                    status: action.status,
                    statusMessage: "",
                    loggedIn: true,
                    accounts: newAccounts,
                    actualAccount: newAccounts.length - 1,
                    byCardZar: action.data.bycard ? action.data.zarizeni.zarizeni : null
                }
            } else {
                return {
                    ...state,
                    status: action.status,
                    statusMessage: action.statusMessage
                }
            }
        case STRAVNIK_LOGOUT:
            let newAccounts = state.accounts && state.accounts.length > 0 ? [...state.accounts] : [];
            if(action.accountIdx !== null) {
                newAccounts.splice(action.accountIdx, 1)
            } else {
                newAccounts.splice(state.actualAccount, 1)
            }
            return {
                ...state,
                accounts: newAccounts,
                actualAccount: newAccounts.length > 0 ? 0 : -1,
                loggedIn: (newAccounts.length > 0)
            }
        case STRAVNIK_GDPR:
            if (action.status === STRAVNIK_GDPR_OK) {
                let newAccountsGDPR = state.accounts.map((acc, idx) => {
                    if(idx === state.actualAccount) {
                        //upravujeme aktualni ucet
                        acc.user = action.data.user
                    }
                    return acc
                })
                return {
                    ...state,
                    status: action.status,
                    statusMessage: "",
                    accounts: newAccountsGDPR
                }
            } else {
                return {
                    ...state,
                    status: action.status,
                    statusMessage: action.statusMessage
                }
            }
        case STRAVNIK_CHANGE_EMAIL:
            if (action.status === STRAVNIK_CHANGE_EMAIL_OK) {
                let newAccountsEml = state.accounts.map((acc, idx) => {
                    if(idx === state.actualAccount) {
                        //upravujeme aktualni ucet
                        acc.user = action.data.user
                    }
                    return acc
                })
                return {
                    ...state,
                    status: action.status,
                    statusMessage: "",
                    accounts: newAccountsEml
                }
            } else {
                return {
                    ...state,
                    status: action.status,
                    statusMessage: action.statusMessage
                }
            }            
        case STRAVNIK_SETTINGS:
            if (action.status === STRAVNIK_SETTINGS_OK) {
                let newAccountsSett = state.accounts.map((acc, idx) => {
                    if(idx === state.actualAccount) {
                        //upravujeme aktualni ucet
                        acc.user = action.data.user
                    }
                    return acc
                })
                return {
                    ...state,
                    status: action.status,
                    statusMessage: "",
                    accounts: newAccountsSett
                }
            } else {
                return {
                    ...state,
                    status: action.status,
                    statusMessage: action.statusMessage
                }
            }
        case STRAVNIK_INFO:
            if (action.status === STRAVNIK_INFO_OK) {
                let newAccountsInfo = state.accounts.map((acc, idx) => {
                    if(idx === state.actualAccount) {
                        //upravujeme aktualni ucet
                        acc.user = action.data.user
                        acc.zarizeni = action.data.zarizeni
                    }
                    return acc
                })
                return {
                    ...state,
                    status: action.status,
                    statusMessage: "",
                    accounts: newAccountsInfo
                }
            } else {
                return {
                    ...state,
                    status: action.status,
                    statusMessage: action.statusMessage
                }
            }                      
        case STRAVNIK_AUTH_SET_LANG:
            return {
                ...state,
                lang: action.data
            }           
        case STRAVNIK_SET_ACTIVE_ACCOUNT:
            return {
                ...state,
                actualAccount: action.data
            }
        case JIDELNA_SELECT:
            return {
                ...state,
                preselect: action.data
            }            
        default:
            if (action.statusMessage && action.statusMessage.generalState && action.statusMessage.generalState === "notAuthorized") {
                let newAccounts = state.accounts && state.accounts.length > 0 ? [...state.accounts] : [];
                newAccounts.splice(state.actualAccount, 1)
                return {
                    ...state,
                    accounts: newAccounts,
                    actualAccount: newAccounts.length > 0 ? 0 : -1,
                    loggedIn: (newAccounts.length > 0)
                }
            } else {
                return state;
            }
    }
}