import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import JidelnaItem from '../components/items/JidelnaItem';

import MainView from '../components/views/MainView';
import { jidelnaList, jidelnaSelect } from '../redux/actions/jidelna';
import { useTranslation } from 'react-i18next';
import { removeDiacritics } from '../utils/conversions';
import { Form } from 'react-bootstrap';
import { Redirect } from 'react-router';
import { useForm } from 'react-hook-form';
import Input from '../components/forms/Input';

const ZarizeniSelectionScreen = (props) => {

  const { register, handleSubmit, formState: { isDirty, isValid, errors } } = useForm();

  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()
  let {jidelny} = useSelector(mapStateToProps)

  const [filterText, setFilterText] = useState("")
  const [doRedirect, enableRedirect] = useState("0000")

  useEffect(() => {
      let countryFilter = null
      switch(i18n.language) {
        case 'cs':
          countryFilter = 'CZ'
          break;
        case 'sk':
          countryFilter = 'SK'
          break;
        default:
          break;
      }
      //dispatch(jidelnaList(countryFilter))
      dispatch(jidelnaList())
    }, [dispatch, i18n.language]
  );

  const jidelnaSelected = (selZar) => {
    dispatch(jidelnaSelect(selZar))
    enableRedirect(selZar.zarizeni)
  }

  const onSearch = (searchText) => {
    return new Promise((resolve, reject) => {
        setFilterText(searchText)
        resolve();
    });
  }

  let jidelnySectionedAndFiltered = []
  let filterDia = removeDiacritics(filterText)

  jidelny.filter((jid) => removeDiacritics(jid.mesto).includes(filterDia) || jid.zarizeni.includes(filterDia) || removeDiacritics(jid.nazev).includes(filterDia)).map((jid) => {
    let fidx = jidelnySectionedAndFiltered.findIndex((val) => val.title === jid.mesto.trim())
    if(fidx >= 0) {
      jidelnySectionedAndFiltered[fidx].data.push({...jid})
    } else {
      jidelnySectionedAndFiltered.push({title: jid.mesto.trim(), data: [{...jid}]})
    }
    return jid
  })

  jidelnySectionedAndFiltered = jidelnySectionedAndFiltered.map((mesto) => {
    mesto.data.sort((a, b) => a.nazev.trim().localeCompare(b.nazev.trim()))
    return mesto
  })
  
  jidelnySectionedAndFiltered.sort((a, b) => a.title.localeCompare(b.title))

  const onSubmit = data => onSearch(data.search);

  return (
    <MainView>
      <div className="baseMarginView">
      <h2 className="wizard_header">{t('navigator.headers.selection')}</h2>
      {doRedirect && doRedirect != "0000" && <Redirect to={"/prihlaseni/"+doRedirect} />}
      <Form onSubmit={handleSubmit(onSubmit)} className="mt-3">
        <Input register={register} errors={errors} name="search" placeholder={t('zarizeni.search.placeholder')} onChange={(e) => onSearch(e.target.value)}  />
      </Form>
      {jidelnySectionedAndFiltered && jidelnySectionedAndFiltered.map((mesto) => 
        <div className="card mb-3" key={mesto.title}>
          <div className="card-header">
          {mesto.title}
          </div>
          <ul className="list-group list-group-flush">
            {mesto.data && mesto.data.map((item) => 
            <JidelnaItem key={item.zarizeni} onPress={() => jidelnaSelected(item)} data={item} />
            )}
        </ul>
      </div>
      )}
      </div>
    </MainView>
  )

}

const mapStateToProps = (state) => {
  return {
      jidelny: state.jidelna.list
  }
}

export default ZarizeniSelectionScreen