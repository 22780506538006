import { Button } from 'react-bootstrap';
import React from 'react';

const AppButton = (props) => {

    return (
      <Button ref={props.fref} className="btn_base" variant={props.variant} disabled={props.disabled} size={props.sm ? "sm" : ""} style={props.style} onClick={props.disabled ? () => {}: props.onPress}>
        {props.children}
        {props.label && <span style={props.style_label} className="btn_label">{props.label}</span>}
      </Button>
    );

}

export default AppButton