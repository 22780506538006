import api from '../../utils/axios'
import config from '../../utils/config'
import {getMessage} from "../../utils/errorMessages";

export const JIDELNA_LIST = 'JIDELNA_LIST'
export const JIDELNA_DETAIL = 'JIDELNA_DETAIL'
export const JIDELNA_SELECT = 'JIDELNA_SELECT'

export const JIDELNA_LIST_INPROGRESS = 'JIDELNA_LIST_INPROGRESS'
export const JIDELNA_LIST_OK = 'JIDELNA_LIST_OK'
export const JIDELNA_LIST_ERROR = 'JIDELNA_LIST_ERROR'

export const JIDELNA_DETAIL_INPROGRESS = 'JIDELNA_DETAIL_INPROGRESS'
export const JIDELNA_DETAIL_OK = 'JIDELNA_DETAIL_OK'
export const JIDELNA_DETAIL_ERROR = 'JIDELNA_DETAIL_ERROR'

export const jidelnaList = (country) => dispatch =>
    new Promise(function (resolve, reject) {
        dispatch({
            type: JIDELNA_LIST,
            status: JIDELNA_LIST_INPROGRESS
        })
        api().get('/', {params: {v: config.versionAPI, action: 'jidelny', country: country}}).then((payload) => {
            resolve()
            dispatch({
                type: JIDELNA_LIST,
                status: JIDELNA_LIST_OK,
                data: payload.data
            })
        }).catch((error) => {
            reject(error)
            dispatch({
                type: JIDELNA_LIST,
                status: JIDELNA_LIST_ERROR,
                statusMessage: getMessage(error, true)
            })
        })
    })
 
export const jidelnaDetail = (zarizeni) => dispatch =>
    new Promise(function (resolve, reject) {
        dispatch({
            type: JIDELNA_DETAIL,
            status: JIDELNA_DETAIL_INPROGRESS
        })
        api().get('/', {params: {v: config.versionAPI, action: 'jidelna', zarizeni: zarizeni}}).then((payload) => {
            resolve()
            dispatch({
                type: JIDELNA_DETAIL,
                status: JIDELNA_DETAIL_OK,
                data: payload.data
            })
        }).catch((error) => {
            reject(error)
            dispatch({
                type: JIDELNA_DETAIL,
                status: JIDELNA_DETAIL_ERROR,
                statusMessage: getMessage(error, true)
            })
        })
    })    
    
export const jidelnaSelect = (data) => {
    return (dispatch) => {
        dispatch({
            type: JIDELNA_SELECT,
            data: data
        })
    }
}