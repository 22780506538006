import React from 'react';

import { useTranslation } from 'react-i18next';
import { localizedDate } from '../../utils/conversions';

import OdebranoItem from './OdebranoItem';
import HodnoceniButton from './HodnoceniButton';
import JidelnicekRadioButton from './JidelnicekRadioButton';

const JidelnicekItem = ({data, odebrane, bigger}) => {

  let {t, i18n} = useTranslation()

  let odebraneShow = {
    snidane: odebrane && data.snidane && data.snidane.odebrano && data.snidane.odebrano.show,
    obedy: odebrane && data.obedy && data.obedy.odebrano && data.obedy.odebrano.show,
    vecere: odebrane && data.vecere && data.vecere.odebrano && data.vecere.odebrano.show
  }

  return (
    <>
      <div className="jidelnicek_outer">
        <div>
          <span className="heading jidelnicek_date">{localizedDate(data.datum,'DD.MM.',i18n.language)}</span>
          <span className="heading">{localizedDate(data.datum,'dddd',i18n.language)}</span>
        </div>
        <div className="jidelnicek_inner jidelnicek_inner_light">
        {data.snidane && data.snidane.data && data.snidane.data.length > 0 &&
          <>
            <div className="jidelnicek_heading_view">
              <span className="jidelnicek_heading">{data.snidane.useAsSoup? t('jidelnicek.soup') : (data.snidane.ownName ? data.snidane.ownName : t('jidelnicek.breakfast'))}</span>
            </div>
            <div className="odebrano_wrapper">
                <div className={bigger ? "bigger-card" : ""}>
                  {data.snidane.data.map((snidane) => {
                      return {label: snidane.typ === "unorder" ? (t('jidelnicek.unorder') + (snidane.nazev === '#N' ? ' ('+t('jidelnicek.reasonN')+')' : '') + (snidane.nazev === '#9' ? ' ('+t('jidelnicek.reason9')+')' : '')): snidane.nazev, value: snidane.hodnota, ...snidane}
                    }).map((obj, i) => (
                      <JidelnicekRadioButton key={i} obj={obj} data={data} i={i} typ="snidane" />
                    ))}
                </div> 
                {odebraneShow.snidane &&
                <OdebranoItem odebrano={data.snidane.odebrano.value} />}                    
            </div>
          </>
        }    

        {data.obedy && data.obedy.data && data.obedy.data.length > 0 &&
          <>
          {data.obedy.hasHeading && 
              <div className="jidelnicek_heading_view">
                  <span className="jidelnicek_heading">{t('jidelnicek.lunch')}</span>
              </div>}
              <div className="odebrano_wrapper">
                <div className={bigger ? "bigger-card" : ""}>
                {data.obedy.data.map((obed) => {
                    return {label: obed.typ === "unorder" ? (t('jidelnicek.unorder') + (obed.nazev === '#N' ? ' ('+t('jidelnicek.reasonN')+')' : '') + (obed.nazev === '#9' ? ' ('+t('jidelnicek.reason9')+')' : '')): obed.nazev, value: obed.hodnota, ...obed}
                  }).map((obj, i) => (
                    <JidelnicekRadioButton key={i} obj={obj} data={data} i={i} typ="obedy" />
                  ))}  
                    </div> 
                {odebraneShow.obedy &&
               <OdebranoItem odebrano={data.obedy.odebrano.value} />}
            </div>
            {data.obedy.hodnoceni && <HodnoceniButton den={data.den} jidlo={data.obedy.data.filter((obed) => obed.objednano === true)[0]} />}     
          </>
        }

        {data.vecere && data.vecere.data && data.vecere.data.length > 0 &&
          <>
            <div className="jidelnicek_heading_view">
              <span className="jidelnicek_heading">{data.vecere.isVydejna ? t('jidelnicek.pickup') : (data.vecere.useAsSoup? t('jidelnicek.soup') : (data.vecere.ownName ? data.vecere.ownName : t('jidelnicek.dinner')))}</span>
            </div>
            <div className="odebrano_wrapper">
              <div className={bigger ? "bigger-card" : ""}>
                {data.vecere.data.map((vecere) => {
                    return {label: vecere.typ === "unorder" ? (t('jidelnicek.unorder') + (vecere.nazev === '#N' ? ' ('+t('jidelnicek.reasonN')+')' : '') + (vecere.nazev === '#9' ? ' ('+t('jidelnicek.reason9')+')' : '')): vecere.nazev, value: vecere.hodnota, ...vecere}
                  }).map((obj, i) => (
                    <JidelnicekRadioButton key={i} obj={obj} data={data} i={i} typ="vecere" />
                  ))}  
                </div> 
                {odebraneShow.vecere &&
                <OdebranoItem odebrano={data.vecere.odebrano.value} />}  
            </div>                  
          </>
        }              
        </div>
        </div>
    </>
  );

}

export default JidelnicekItem