import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import AppButton from '../buttons/AppButton';
import BoxItem from '../items/BoxItem';
import Input from './Input';

const ChangeEmail = (props) => {
    const { t } = useTranslation();
    const { register, formState: { isDirty, isValid, errors }, handleSubmit } = useForm();

    const onSubmit = (data) => {
        props.handleSubmit && props.handleSubmit(data)
    }

    return (
        <>
            <span className="input_label">{t('account.email.original')}</span>
            <BoxItem value={props.email} />
            <Input register={register} errors={errors} name="eml" label={t('account.email.new')} 
                rules={{pattern: /^[\w]{1,}[\w.+-]{0,}@[\w-]{2,}([.][a-zA-Z]{2,}|[.][\w-]{2,}[.][a-zA-Z]{2,})$/ , required: true}} 
                invalidMessage={t('validation.email')} 
                keyboardType="email-address"
            />
            <AppButton style={{marginTop: 30}} onPress={handleSubmit((data) => onSubmit(data))} label={t('account.email.confirm')} />
        </>
    )
}

export default ChangeEmail