import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import MainView from '../components/views/MainView';
import ProfileItem from '../components/items/ProfileItem';
import AppButton from '../components/buttons/AppButton';
import { stravnikSetAccount } from '../redux/actions/stravnik';
  
const ProfileScreen = ({ navigation }) => {
  
    const dispatch = useDispatch()
    let {activeAccount, accounts} = useSelector(mapStateToProps)

    const { t } = useTranslation();

    return (
      <MainView loggedOnly={true}>
          <div className="baseMarginView">
          <h2 className="wizard_header">{t('navigator.tabs.menu')} </h2>
        </div>
        {/* 
        <ScrollView style={mainStyles.baseMarginView}>

            <Text style={[mainStyles.baseText, isDarkMode ? mainStyles.textOnDark : {}]}>{t('account.profile.info')}</Text>

            <View style={[mainStyles.horizontalView, {marginVertical: 20}]}>
                <View style={{flex: 1}}>
                  {accounts && accounts.map((acnt, idx) => 
                    <ProfileItem key={acnt.user.username+"/"+idx} data={acnt} idx={idx} onPress={() => {
                      dispatch(stravnikSetAccount(idx))
                      navigation.popToTop()
                    }} />
                  )}
                </View>
            </View>

            {accounts && accounts.length < 10 &&
            <View style={mainStyles.horizontalCenteredView}>
            <AppButton sm onPress={() => navigation.navigate("ZarizeniSelect")} label={t('account.profile.add')}>
              <MaterialIcons style={{marginRight: 4}} name="person-add" color={colors.white} size={18} />
            </AppButton>
            </View>}
            
        </ScrollView>
        */}
      </MainView>
    )
  
}

const mapStateToProps = (state) => {
  return {
      activeAccount: state.auth.actualAccount > -1 ? state.auth.accounts[state.auth.actualAccount] : null,
      accounts: state.auth.accounts
  }
}

export default ProfileScreen