import axios from 'axios'
import config from './config'

export default (getState, forceAccountIdx = null) => {
    let authState = getState ? getState().auth : {}
    let token = null
    if(forceAccountIdx !== null) {
        token = authState.accounts[forceAccountIdx].token
    } else {
        if(authState.actualAccount > -1) {
            token = authState.accounts[authState.actualAccount].token
        }
    }
    return axios.create({
        baseURL: config.API,
        timeout: 10000,
        headers: {
            common: {
                'Authorization': token ? 'Bearer '+token : null,
                'X-Device-ID': "WEB"
            }
        }
    })
}

