
import React from 'react';

const ActivityOverlayView = (props) => {
  
    return (
      <div className="overlayView">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    )
  
}

export default ActivityOverlayView