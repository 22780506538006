import { applyMiddleware, compose, createStore } from 'redux';
import rootReducer from './reducers';
import {save, load} from "redux-localstorage-simple"

import thunkMiddleware from 'redux-thunk';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// create the store
export default () => {
  return createStore(rootReducer, load({states: ['auth']}), composeEnhancers(
    applyMiddleware(thunkMiddleware, save({states: ['auth']}))
))
}