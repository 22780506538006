
import config from '../../utils/config'
import api from '../../utils/axios'
import {getMessage} from "../../utils/errorMessages";

export const STRAVNIK_LOGIN = 'STRAVNIK_LOGIN'
export const STRAVNIK_LOGOUT = 'STRAVNIK_LOGOUT'
export const STRAVNIK_GDPR = 'STRAVNIK_GDPR'
export const STRAVNIK_INFO = 'STRAVNIK_INFO'
export const STRAVNIK_FINANCE = 'STRAVNIK_FINANCE'
export const STRAVNIK_FINANCE_SEND = 'STRAVNIK_FINANCE_SEND'
export const STRAVNIK_SETTINGS = 'STRAVNIK_SETTINGS'
export const STRAVNIK_CHANGE_EMAIL = 'STRAVNIK_CHANGE_EMAIL'
export const STRAVNIK_CHANGE_PWD = 'STRAVNIK_CHANGE_PWD'
export const STRAVNIK_RECOVER_PWD = 'STRAVNIK_RECOVER_PWD'
export const STRAVNIK_SET_ACTIVE_ACCOUNT = 'STRAVNIK_SET_ACTIVE_ACCOUNT'

export const STRAVNIK_LOGIN_INPROGRESS = 'STRAVNIK_LOGIN_INPROGRESS'
export const STRAVNIK_LOGIN_OK = 'STRAVNIK_LOGIN_OK'
export const STRAVNIK_LOGIN_ERROR = 'STRAVNIK_LOGIN_ERROR'

export const STRAVNIK_GDPR_INPROGRESS = 'STRAVNIK_GDPR_INPROGRESS'
export const STRAVNIK_GDPR_OK = 'STRAVNIK_GDPR_OK'
export const STRAVNIK_GDPR_ERROR = 'STRAVNIK_GDPR_ERROR'

export const STRAVNIK_INFO_INPROGRESS = 'STRAVNIK_INFO_INPROGRESS'
export const STRAVNIK_INFO_OK = 'STRAVNIK_INFO_OK'
export const STRAVNIK_INFO_ERROR = 'STRAVNIK_INFO_ERROR'

export const STRAVNIK_FINANCE_INPROGRESS = 'STRAVNIK_FINANCE_INPROGRESS'
export const STRAVNIK_FINANCE_OK = 'STRAVNIK_FINANCE_OK'
export const STRAVNIK_FINANCE_ERROR = 'STRAVNIK_FINANCE_ERROR'

export const STRAVNIK_FINANCE_SEND_INPROGRESS = 'STRAVNIK_FINANCE_SEND_INPROGRESS'
export const STRAVNIK_FINANCE_SEND_OK = 'STRAVNIK_FINANCE_SEND_OK'
export const STRAVNIK_FINANCE_SEND_ERROR = 'STRAVNIK_FINANCE_SEND_ERROR'

export const STRAVNIK_SETTINGS_INPROGRESS = 'STRAVNIK_SETTINGS_INPROGRESS'
export const STRAVNIK_SETTINGS_OK = 'STRAVNIK_SETTINGS_OK'
export const STRAVNIK_SETTINGS_ERROR = 'STRAVNIK_SETTINGS_ERROR'

export const STRAVNIK_CHANGE_EMAIL_INPROGRESS = 'STRAVNIK_CHANGE_EMAIL_INPROGRESS'
export const STRAVNIK_CHANGE_EMAIL_OK = 'STRAVNIK_CHANGE_EMAIL_OK'
export const STRAVNIK_CHANGE_EMAIL_ERROR = 'STRAVNIK_CHANGE_EMAIL_ERROR'

export const STRAVNIK_CHANGE_PWD_INPROGRESS = 'STRAVNIK_CHANGE_PWD_INPROGRESS'
export const STRAVNIK_CHANGE_PWD_OK = 'STRAVNIK_CHANGE_PWD_OK'
export const STRAVNIK_CHANGE_PWD_ERROR = 'STRAVNIK_CHANGE_PWD_ERROR'

export const STRAVNIK_RECOVER_PWD_INPROGRESS = 'STRAVNIK_RECOVER_PWD_INPROGRESS'
export const STRAVNIK_RECOVER_PWD_OK = 'STRAVNIK_RECOVER_PWD_OK'
export const STRAVNIK_RECOVER_PWD_ERROR = 'STRAVNIK_RECOVER_PWD_ERROR'

export const STRAVNIK_LOGOUT_OK = 'STRAVNIK_LOGOUT_OK'

export const STRAVNIK_AUTH_SET_LANG = 'STRAVNIK_AUTH_SET_LANG'

export const login = (loginData, action = "login") => dispatch =>
    new Promise(function (resolve, reject) {
        dispatch({
            type: STRAVNIK_LOGIN,
            status: STRAVNIK_LOGIN_INPROGRESS
        })

        api().post('/',{...loginData, deviceName: "WEB"}, {params: {v: config.versionAPI, action: action}}).then((payload) => {
            resolve()
            dispatch({
                type: STRAVNIK_LOGIN,
                status: STRAVNIK_LOGIN_OK,
                data: payload.data.data
            })
        }).catch((error) => {
            let err = getMessage(error, true)
            reject(err)
            dispatch({
                type: STRAVNIK_LOGIN,
                status: STRAVNIK_LOGIN_ERROR,
                statusMessage: err
            })
        })
            
    })

export const logout = (accountIdx = null) => (dispatch, getState) =>
    new Promise(function (resolve, reject) {
        api(getState, accountIdx).get('/', {params: {v: config.versionAPI, action: 'logout'}}).then(() => {
            dispatch({
                type: STRAVNIK_LOGOUT,
                status: STRAVNIK_LOGOUT_OK,
                statusMessage: null,
                accountIdx: accountIdx
            })
            resolve()
        }).catch(() => {
            dispatch({
                type: STRAVNIK_LOGOUT,
                status: STRAVNIK_LOGOUT_OK,
                statusMessage: null
            })
            reject()
        })        
    })


export const gdprConfirm = () => {
    return (dispatch, getState) => {

        dispatch({
            type: STRAVNIK_GDPR,
            status: STRAVNIK_GDPR_INPROGRESS
        })

        api(getState).post('/', null, {params: {v: config.versionAPI, action: 'gdpr'}}).then((payload) => {
            dispatch({
                type: STRAVNIK_GDPR,
                status: STRAVNIK_GDPR_OK,
                data: payload.data.data
            })
        }).catch((error) => {
            let err = getMessage(error, false)
            dispatch({
                type: STRAVNIK_GDPR,
                status: STRAVNIK_GDPR_ERROR,
                statusMessage: err
            })
        })
    }
}


export const gdprRemove = () => (dispatch, getState) =>
    new Promise(function (resolve, reject) {

        dispatch({
            type: STRAVNIK_GDPR,
            status: STRAVNIK_GDPR_INPROGRESS
        })

        api(getState).post('/', null, {params: {v: config.versionAPI, action: 'gdprremove'}}).then((payload) => {
            dispatch({
                type: STRAVNIK_GDPR,
                status: STRAVNIK_GDPR_OK,
                data: payload.data.data
            })
            resolve()
        }).catch((error) => {
            let err = getMessage(error, false)
            dispatch({
                type: STRAVNIK_GDPR,
                status: STRAVNIK_GDPR_ERROR,
                statusMessage: err
            })
            reject(err)
        })
    })


export const saveSettings = (data) => {
    return (dispatch, getState) => {

        dispatch({
            type: STRAVNIK_SETTINGS,
            status: STRAVNIK_SETTINGS_INPROGRESS
        })

        api(getState).post('/', {...data}, {params: {v: config.versionAPI, action: 'sett'}}).then((payload) => {
            dispatch({
                type: STRAVNIK_SETTINGS,
                status: STRAVNIK_SETTINGS_OK,
                data: payload.data.data
            })
        }).catch((error) => {
            let err = getMessage(error, false)
            dispatch({
                type: STRAVNIK_SETTINGS,
                status: STRAVNIK_SETTINGS_ERROR,
                statusMessage: err
            })
        })
    }
}

export const changeEmail = (data)  => (dispatch, getState) =>
    new Promise(function (resolve, reject) {
        dispatch({
            type: STRAVNIK_CHANGE_EMAIL,
            status: STRAVNIK_CHANGE_EMAIL_INPROGRESS
        })

        api(getState).post('/', {...data}, {params: {v: config.versionAPI, action: 'eml'}}).then((payload) => {
            resolve()
            dispatch({
                type: STRAVNIK_CHANGE_EMAIL,
                status: STRAVNIK_CHANGE_EMAIL_OK,
                data: payload.data.data
            })
        }).catch((error) => {
            let err = getMessage(error, false)
            dispatch({
                type: STRAVNIK_CHANGE_EMAIL,
                status: STRAVNIK_CHANGE_EMAIL_ERROR,
                statusMessage: err
            })
            reject(err)
        })
    })


export const changePwd = (data) => (dispatch, getState) =>
    new Promise(function (resolve, reject) {
        dispatch({
            type: STRAVNIK_CHANGE_PWD,
            status: STRAVNIK_CHANGE_PWD_INPROGRESS
        })

        api(getState).post('/', {...data}, {params: {v: config.versionAPI, action: 'pswd'}}).then((payload) => {
            resolve()
            dispatch({
                type: STRAVNIK_CHANGE_PWD,
                status: STRAVNIK_CHANGE_PWD_OK,
                data: payload.data.data
            })
        }).catch((error) => {
            let err = getMessage(error, false)
            dispatch({
                type: STRAVNIK_CHANGE_PWD,
                status: STRAVNIK_CHANGE_PWD_ERROR,
                statusMessage: err
            })
            reject(err)
        })
    })

export const recoverPassword = (data)  => (dispatch, getState) =>
    new Promise(function (resolve, reject) {
        dispatch({
            type: STRAVNIK_RECOVER_PWD,
            status: STRAVNIK_RECOVER_PWD_INPROGRESS
        })

        api(getState).post('/', {...data}, {params: {v: config.versionAPI, action: 'lostpwd'}}).then((payload) => {
            resolve()
            dispatch({
                type: STRAVNIK_RECOVER_PWD,
                status: STRAVNIK_RECOVER_PWD_OK,
                data: payload.data.data
            })
        }).catch((error) => {
            let err = getMessage(error, true)
            dispatch({
                type: STRAVNIK_RECOVER_PWD,
                status: STRAVNIK_RECOVER_PWD_ERROR,
                statusMessage: err
            })
            reject(err)
        })
    })

export const userInfo = () => {
    return (dispatch, getState) => {

        dispatch({
            type: STRAVNIK_INFO,
            status: STRAVNIK_INFO_INPROGRESS
        })

        api(getState).get('/', {params: {v: config.versionAPI, action: 'userinfo'}}).then((payload) => {
            dispatch({
                type: STRAVNIK_INFO,
                status: STRAVNIK_INFO_OK,
                data: payload.data.data
            })
        }).catch((error) => {
            let err = getMessage(error, false)
            dispatch({
                type: STRAVNIK_INFO,
                status: STRAVNIK_INFO_ERROR,
                statusMessage: err
            })
        })
    }
}

export const financeInfo = () => (dispatch, getState) =>
    new Promise(function (resolve, reject) {

        dispatch({
            type: STRAVNIK_FINANCE,
            status: STRAVNIK_FINANCE_INPROGRESS
        })

        api(getState).get('/', {params: {v: config.versionAPI, action: 'finance'}}).then((payload) => {
            dispatch({
                type: STRAVNIK_FINANCE,
                status: STRAVNIK_FINANCE_OK,
                data: payload.data.data
            })
            resolve()
        }).catch((error) => {
            let err = getMessage(error, false)
            dispatch({
                type: STRAVNIK_FINANCE,
                status: STRAVNIK_FINANCE_ERROR,
                statusMessage: err
            })
            reject(err)
        })
    })

export const stravnikSetAccount = (accIdx) => {
    return (dispatch) => {
        dispatch({
            type: STRAVNIK_SET_ACTIVE_ACCOUNT,
            data: accIdx
        })
    }
}

export const financeSend = () => (dispatch, getState) =>
    new Promise(function (resolve, reject) {

        dispatch({
            type: STRAVNIK_FINANCE_SEND,
            status: STRAVNIK_FINANCE_SEND_INPROGRESS
        })

        api(getState).post('/', {} ,{params: {v: config.versionAPI, action: 'financesend'}}).then((payload) => {
            dispatch({
                type: STRAVNIK_FINANCE_SEND,
                status: STRAVNIK_FINANCE_SEND_OK,
                data: payload.data.data
            })
            resolve()
        }).catch((error) => {
            let err = getMessage(error, false)
            dispatch({
                type: STRAVNIK_FINANCE_SEND,
                status: STRAVNIK_FINANCE_SEND_ERROR,
                statusMessage: err
            })
            reject(err)
        })
    })

export const stravnikAuthSetLang = (lng) => {
    return (dispatch) => {
        dispatch({
            type: STRAVNIK_AUTH_SET_LANG,
            data: lng
        })
    }
}