import { STRAVNIK_FINANCE, STRAVNIK_FINANCE_OK } from "../actions/stravnik";

const iniState = {
    status: null,
    statusMessage: "",
    systemStatus: "",
    finance: null
}
export default (state = iniState, action) => {
    switch (action.type) {
        case STRAVNIK_FINANCE:
            if (action.status === STRAVNIK_FINANCE_OK) {
                return {
                    ...state,
                    status: action.status,
                    statusMessage: "",
                    finance: action.data
                }
            } else {
                return {
                    ...state,
                    status: action.status,
                    statusMessage: action.statusMessage
                }
            }  
        default:
            if (action.statusMessage && action.statusMessage.generalState && action.statusMessage.generalState === "notAuthorized") {
                return iniState;
            } else {
                return state;
            }
    }
}