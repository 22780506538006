import React, { useEffect, useState } from 'react';
import { stravnikAuthSetLang } from '../redux/actions/stravnik';
import LanguageDetector from 'i18next-browser-languagedetector';
import configureI18n from '../i18n';

  
const LangLoader = (props) => {

  const storeLangDetector = {
    name: 'langauth',
  
    lookup(options) {
        //zjisteni jazyka z profilu, pokud neni, tak default
        let authData = props.store.getState().auth
        return authData.lang ? authData.lang : "cs"
    },
  
    cacheUserLanguage(lng, options) {
        //zmenu jazyka ulozime do redux storu
        props.store.dispatch(stravnikAuthSetLang(lng))
    },
  }

  const [i18ready, seti18ready] = useState(false)

  useEffect(() => {
      const languageDetector = new LanguageDetector();
      languageDetector.addDetector(storeLangDetector);
      
      configureI18n(languageDetector, () => seti18ready(true))  
    }, [props.store]
  )
    
  return (
    <>
      {i18ready  && props.children}
    </>
  )
  
}

export default LangLoader