import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import AppButton from '../components/buttons/AppButton';
import BoxItem from '../components/items/BoxItem';
import MainView from '../components/views/MainView';
import { financeInfo, financeSend } from '../redux/actions/stravnik';
import { showAlert } from '../utils/errorMessages';
  
const FinInfoScreen = () => {
  
    const dispatch = useDispatch()
    let {finance, activeAccount} = useSelector(mapStateToProps)
    let [refreshing, setIsRefreshing] = useState(false)

    const onRefresh = () => {
        setIsRefreshing(true);
        dispatch(financeInfo()).then(() => {
          setIsRefreshing(false)
        }).catch(() => {
          setIsRefreshing(false)
        })
    }

    const { t } = useTranslation();

    useEffect(() => {
        dispatch(financeInfo()).catch(() => {})
    }, [activeAccount]);

    const sendFinance = () => {
        dispatch(financeSend()).then(() => {
          showAlert({title: 'finance.send.success.header', message: 'finance.send.success.text'}, t, [{search: '[EMAIL]', value: activeAccount && activeAccount.user && activeAccount.user.email}])
        }).catch((err) => {
          showAlert(err, t)
        })
    }

    let showDetailFininfo = (activeAccount && activeAccount.user && activeAccount.zarizeni && activeAccount.zarizeni.fininfo)

    return (
      <MainView loggedOnly={true}>
        <div className="baseMarginView">
          <h2 className="wizard_header">{t('finance.header')} </h2>
        </div>
        {showDetailFininfo ?
        <div>
          <div className="basePaddingView">
            <pre>{finance}</pre>
            </div>
          <div className="basePaddingView">
          {finance !== "" && finance && <AppButton onPress={() => sendFinance()} label={t('finance.send.button')} />}
          </div>                  
      </div>
        :
            <>{activeAccount && activeAccount.user && activeAccount.user.finance && <>
            <span className="input_label">{t('finance.original.zaloha')}</span>
            <BoxItem value={activeAccount.user.finance.zaloha} />
            <span className="input_label">{t('finance.original.odhlasky1')}</span>
            <BoxItem value={activeAccount.user.finance.odhlasky1} />
            <span className="input_label">{t('finance.original.odhlasky2')}</span>
            <BoxItem value={activeAccount.user.finance.odhlasky2} />
            </>}
            </>
        }
      </MainView>
    )
  
}

const mapStateToProps = (state) => {
  return {
      finance: state.stravnik.finance,
      activeAccount: state.auth.actualAccount > -1 ? state.auth.accounts[state.auth.actualAccount] : null
  }
}

export default FinInfoScreen