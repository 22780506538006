import React from 'react';
import { useTranslation } from 'react-i18next';
import { localizedDate } from '../../utils/conversions';
import { Star, StarOutline } from '@material-ui/icons';

const JidelnicekStaticItem = (props) => {

  let {t, i18n} = useTranslation()

  let {data} = props

  return (
    <>
      <div className="jidelnicek_outer">
        <div>
          <span className="heading jidelnicek_date">{localizedDate(data.datum,'DD.MM.',i18n.language)}</span>
          <span className="heading">{localizedDate(data.datum,'dddd',i18n.language)}</span>
        </div>
        <div className="jidelnicek_inner jidelnicek_inner_light">   
        {data.data && data.data.length > 0 && <>
            {data.data.map((obj, i) => (<div key={data.datum + "/" + i}>
            <div className="jidelnicek_heading_view">
              <span className="jidelnicek_heading">{obj.nadpis}</span>
            </div>
                {obj.hodnoceni !== null ? <>
                      <span className="baseText">{obj.nazev}</span>
                      <div className="justify-content-between rating-show">
                        {obj.hodnoceni.hodnoceni <= 5 ? <Star fontSize="small" color="warning" /> : <StarOutline fontSize="small" color="warning" />}
                        {obj.hodnoceni.hodnoceni <= 4 ? <Star fontSize="small" color="warning" /> : <StarOutline fontSize="small" color="warning" />}
                        {obj.hodnoceni.hodnoceni <= 3 ? <Star fontSize="small" color="warning" /> : <StarOutline fontSize="small" color="warning" />}
                        {obj.hodnoceni.hodnoceni <= 2 ? <Star fontSize="small" color="warning" /> : <StarOutline fontSize="small" color="warning" />}
                        {obj.hodnoceni.hodnoceni <= 1 ? <Star fontSize="small" color="warning" /> : <StarOutline fontSize="small" color="warning" />}
                      </div>
                  </>:<>
                  <span className="baseText">{obj.nazev}</span>
                  </>
                  }</div>
              ))}  
          </>}
        </div>
        </div>
    </>
  );

}

export default JidelnicekStaticItem