import React from 'react';

import FlagCz from  'svg-country-flags/svg/cz.svg'
import FlagSk from  'svg-country-flags/svg/sk.svg'
import FlagEn from  'svg-country-flags/svg/us.svg'
import { useTranslation } from 'react-i18next';
import ImageButton from '../buttons/ImageButton';

const LangSelectBox = (props) => {
    const { i18n } = useTranslation();

    return (
    <div className={"horizontalView horizontalEvenView borderBox baseBackground"} style={props.style}>
        <ImageButton selected={i18n.language === "cs"} onPress={() => i18n.changeLanguage("cs")}>
            <img src={FlagCz} height={30} width={40} alt="CZ"/>
        </ImageButton>
        <ImageButton selected={i18n.language === "sk"}  onPress={() => i18n.changeLanguage("sk")}>
            <img src={FlagSk} height={30} width={40} alt="SK" />
        </ImageButton>
        <ImageButton selected={i18n.language === "en"}  onPress={() => i18n.changeLanguage("en")}>
            <img src={FlagEn} height={30} width={40} alt="EN" />
        </ImageButton>                                
    </div>
    )
}

export default LangSelectBox