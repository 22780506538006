import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';

import MainView from '../components/views/MainView';
import { userInfo } from '../redux/actions/stravnik';
  
const JidelnicekTextScreen = () => {

  const dispatch = useDispatch()
  let {activeAccount} = useSelector(mapStateToProps)

  const { t } = useTranslation();

  useEffect(() => {
      dispatch(userInfo())
  }, [activeAccount]);

  return (
    <MainView loggedOnly={true}>
        <div className="baseMarginView">
          <h2 className="wizard_header">{t('info.buttons.textVersion')} </h2>
        </div>
      {activeAccount && activeAccount.zarizeni && <>
        <p className="wizard_text basePaddingView" style={{ whiteSpace: "pre-wrap"}}>
            {activeAccount.zarizeni.jidelnicek}
        </p>
      </>}
    </MainView>
  )

}

const mapStateToProps = (state) => {
return {
    activeAccount: state.auth.actualAccount > -1 ? state.auth.accounts[state.auth.actualAccount] : null
}
}

export default JidelnicekTextScreen