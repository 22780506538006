import { JIDELNA_DETAIL, JIDELNA_DETAIL_OK, JIDELNA_LIST, JIDELNA_LIST_OK, JIDELNA_SELECT } from "../actions/jidelna";

const iniState = {
    status: null,
    statusMessage: "",
    list: [],
    selected: {}
}
export default (state = iniState, action) => {
    switch (action.type) {
        case JIDELNA_LIST:
            if (action.status === JIDELNA_LIST_OK) {
                return {
                    ...state,
                    status: action.status,
                    statusMessage: "",
                    list: action.data.jidelny
                }
            } else {
                return {
                    ...state,
                    status: action.status,
                    statusMessage: action.statusMessage
                }
            }
        case JIDELNA_DETAIL:
            if (action.status === JIDELNA_DETAIL_OK) {
                return {
                    ...state,
                    status: action.status,
                    statusMessage: "",
                    selected: action.data.jidelna
                }
            } else {
                return {
                    ...state,
                    status: action.status,
                    statusMessage: action.statusMessage
                }
            }
        default:
            if (action.statusMessage && action.statusMessage.generalState && action.statusMessage.generalState === "notAuthorized") {
                return iniState;
            } else {
                return state;
            }
    }
}