import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AppButton from '../components/buttons/AppButton';
import MainView from '../components/views/MainView';
import { userInfo } from '../redux/actions/stravnik';
import { Update, Restaurant, AssignmentOutlined } from '@material-ui/icons';
  
const InfoScreen = () => {
  
    const dispatch = useDispatch()
    let {activeAccount} = useSelector(mapStateToProps)
    const history = useHistory();

    const { t } = useTranslation();

    useEffect(() => {
        dispatch(userInfo())
    }, [activeAccount]);

    let showTextJidelnicek = (activeAccount && activeAccount.user && activeAccount.zarizeni && activeAccount.zarizeni.jidelnicek)

    return (
      <MainView loggedOnly={true}>
          <div className="baseMarginView">
            <h2 className="wizard_header">{t('info.header')} </h2>

            <div className="basePaddingView">
              <AppButton onPress={() => history.push("/jidelnicek-vypis")} label={t('info.buttons.staticVersion')} style={{marginRight: 5}}>
                <Restaurant fontSize="small" style={{marginRight: 4}}/>
            </AppButton>

            <AppButton onPress={() => history.push("/casove-omezeni")} label={t('info.buttons.deadlines')} style={{marginRight: 5}}>
              <Update fontSize="small" style={{marginRight: 4}}/>
            </AppButton>

            {showTextJidelnicek != "" && showTextJidelnicek &&
            <AppButton onPress={() => history.push("/jidelnicek-text")} label={t('info.buttons.textVersion')} style={{marginRight: 5}}>
              <AssignmentOutlined fontSize="small" style={{marginRight: 4}}/>
            </AppButton>}

            </div>

            {activeAccount && activeAccount.zarizeni && <>
              <p className="wizard_text basePaddingView" style={{ whiteSpace: "pre-wrap"}}>
                {activeAccount.zarizeni.info}
              </p>
            </>}
        </div>
      </MainView>
    )
  
}

const mapStateToProps = (state) => {
  return {
      activeAccount: state.auth.actualAccount > -1 ? state.auth.accounts[state.auth.actualAccount] : null
  }
}

export default InfoScreen