import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import LangSelectBox from '../components/items/LangSelectBox';
import MainView from '../components/views/MainView';
import { saveSettings } from '../redux/actions/stravnik';
import { Form } from 'react-bootstrap';

const SettingsScreen = () => {

  let {activeAccount} = useSelector(mapStateToProps)
  const dispatch = useDispatch();
  let [settings, setSettings] = useState({zobrnove: activeAccount.user.zobrnove, odebrane: activeAccount.user.odebrane})

  useEffect(() => {
    setSettings({zobrnove: activeAccount.user.zobrnove, odebrane: activeAccount.user.odebrane})
  }, [activeAccount]);  

  const toggleSwitch = (field, val) => {
    console.log(field);
    console.log(val);
    let send = {
      zobrnove: field === "zobrnove" ? val : activeAccount.user.zobrnove,
      odebrane: field === "odebrane" ? val : activeAccount.user.odebrane,
    }
    dispatch(saveSettings(send));
    setSettings(send)
  }  

  console.log(activeAccount.user);
  console.log(settings);
  
  const { t } = useTranslation();
  
  return (
    <MainView loggedOnly={true}>
      <div className="baseMarginView">
      <h2 className="wizard_header">{t('navigator.tabs.settings')} </h2>
      </div>

      <span className="heading">{t('settings.language.header')}</span>
      <LangSelectBox/>


      <span className="heading">{t('settings.settings.header')}</span>
      <div className="basePaddingView">
      <Form.Check 
        custom
        checked={settings.zobrnove}
        type="checkbox"
        label={t('settings.settings.onlynew')}
        value={settings.zobrnove}
        name={`sett-onlynew`}
        id={`sett-onlynew`}
        onChange={(e) => toggleSwitch('zobrnove',!settings.zobrnove)}
      />
      {activeAccount && activeAccount.zarizeni && activeAccount.zarizeni.odebrane &&
      <Form.Check 
        custom
        checked={settings.odebrane}
        type="checkbox"
        label={t('settings.settings.eaten')}
        value={settings.odebrane}
        name={`sett-eaten`}
        id={`sett-eaten`}
        onChange={(e) => toggleSwitch('odebrane',!settings.odebrane)}
      />}
      </div>
 
    </MainView>
  )
  
}

const mapStateToProps = (state) => {
  return {
    activeAccount: state.auth.actualAccount > -1 ? state.auth.accounts[state.auth.actualAccount] : null
  }
}

export default SettingsScreen