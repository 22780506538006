import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import Input from '../components/forms/Input';
import MainView from '../components/views/MainView';
import { login } from '../redux/actions/stravnik';
import { showAlert } from '../utils/errorMessages';
import sha1 from 'js-sha1';

const LoginByCardScreen = ({ route, navigation }) => {
    const { t } = useTranslation();
    const { register, reset , formState: { isDirty, isValid, errors }, handleSubmit } = useForm({mode: 'onChange'});
    //const { closeAfterLogin } = route.params
    let { selzar, hashcheck } = useParams();
    let [ refreshCounter, addCounter ] = useState(1);

    let history = useHistory();

    const dispatch = useDispatch();

    const onSubmit = (data, jidelna) => {
        dispatch(login({...data, hashcheck: hashcheck, kodzar: selzar}, "logincard")).then(() => {
            history.push("/jidelnicek")
        }).catch((err) => {
            showAlert(err, t)
            reset()
            addCounter(refreshCounter + 1)
        })
    }

    const addCounterEvent = () => {
        addCounter(refreshCounter + 1)
    }

    document.removeEventListener('click', addCounterEvent);
    document.addEventListener('click', addCounterEvent);
    
    const hashValid = sha1('karta'+selzar) === hashcheck;

    return (
        <MainView isCardLogin={true}>
            {hashValid && <div className="baseMarginView">
                <h2 className="wizard_header">{t('login.card.heading')}</h2>
                
                <Form onSubmit={handleSubmit(onSubmit)} className="mt-3">                    
                    <Input overrideType={"password"} autoFocus={refreshCounter} register={register} errors={errors} name="cardno" rules={{required: true}} invalidMessage={t('validation.required')} />
                </Form>

            </div>}
        </MainView>
    )

}

export default LoginByCardScreen